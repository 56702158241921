import React, { useEffect, useRef, useState } from 'react'
import { Row, Col, Modal } from 'react-bootstrap'
import { FiPaperclip } from 'react-icons/fi'
import { useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../providers/AuthProvider'
import {
  addImagesToIssue,
  addUserIssue,
  addUserIssueComment,
  getUserIssue,
} from '../services/dataslayer'
import { formatDateIndex } from '../utility/format'
import { toast } from 'react-toastify'
import { FaCopy, FaImage } from 'react-icons/fa'
import { config } from '../Constants'
import ButtonMMM from './ButtonMMM'

function getFileExtension(filename) {
  if (typeof filename !== 'string' || filename.lastIndexOf('.') === -1) {
    return null
  }
  const extension = filename.substring(filename.lastIndexOf('.') + 1)
  return extension || null
}

export default function Issue({ pushHeader, popHeader }) {
  const { issueId } = useParams()
  const { token } = useAuth()
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const filesRef = useRef()
  const commentFilesRef = useRef()
  const [showUpload, setShowUpload] = useState(false)
  const [selectedFiles, setSelectedFiles] = useState([])

  const [commentFiles, setCommentFiles] = useState([])

  useEffect(() => {
    pushHeader(t('View issue'))
    return () => popHeader()
  }, [])

  const { data: issue } = useQuery(
    ['issue', issueId],
    async () => {
      const response = await getUserIssue({ token, issueId })
      if (response.ok) return await response.json()
      throw new Error('Failed to fetch issue')
    },
    { staleTime: Infinity },
  )

  useEffect(() => {
    window.$('#new_summernote_entry').summernote({
      tabsize: 2,
      height: 200,
      callbacks: {
        onImageUpload: function (files) {
          toast.error(
            'In order to upload images, click on the "Attach files" button.',
          )
          return false
        },
      },
    })
    return () => window.$('#new_summernote_entry').summernote('destroy')
  }, [])

  useEffect(() => {
    if (issue?.data?.issue_comments?.length) {
      let code = ''
      issue?.data?.issue_comments.forEach((comment) => {
        code += `
        <div class="row mt-2 text-sm px-3 max-w-[98%]">
          <div class="col-note-img px-0 ps-2 mt-2">
          <img src=${comment.picture} class="max-w-full rounded-[0.25rem]" alt="profile picture"/>
          </div>
          <div class="col-note-content">
            <strong>${comment.first_name}</strong>
            <strong>${comment.last_name}</strong>
            <span class="text-xs">${formatDateIndex(comment.created)}</span>
              ${comment?.attachments
                ?.map(
                  (
                    a,
                    i,
                  ) => `<a class="ms-2" href=${`${config.DATASLAYER_URL}/img/issues/${a.path}`} target="blank">
              ${i + 1}.${getFileExtension(a.path)}</a>
              ${i === comment.attachments.length - 1 ? '' : `<span class="ms-2 file-bar">/</span>`}`,
                )
                .join('')}
            <div class="mt-1">
              ${comment.comment}
            </div>
            <hr/>
          </div>
        </div>
        `
      })
      window.$('#comment_history').html(code)
    }
  }, [issue])

  return (
    <Row className="mx-4">
      <Col xl={3} sm={12}>
        <Row className="text-sm [&>*]:py-3 [&>*:nth-child(odd)]:border-l [&>*:nth-child(odd)]:border-t [&>*]:border-[#323030] [&>*:nth-child(even)]:border-l [&>*:nth-child(even)]:border-t [&>*:nth-child(even)]:border-r">
          <Col xs={6}>#</Col>
          <Col xs={6}>{issue?.data?.id}</Col>
          <Col xs={6}>{t('User account')}</Col>
          <Col xs={6}>
            {issue?.data?.email}
            <FaCopy
              className="inline-block ms-2 p-2 rounded-lg border-white border-1 cursor-pointer hover:text-body hover:bg-white duration-100 active:scale-95"
              size={30}
              onClick={() => {
                navigator.clipboard.writeText(issue?.data?.email)
                toast.success('Copied to clipboard')
              }}
            />
          </Col>
          <Col xs={6}>{t('Alternative email')}</Col>
          <Col xs={6}>{issue?.data?.alternative_email || '--'}</Col>
          <Col xs={6}>{t('Service')}</Col>
          <Col xs={6}>{issue?.data?.service}</Col>
          <Col xs={6}>{t('Datasource')}</Col>
          <Col xs={6}>{issue?.data?.datasource}</Col>
          <Col xs={6}>{t('Topic')}</Col>
          <Col xs={6}>{issue?.data?.topic}</Col>
          <Col xs={6}>{t('Status')}</Col>
          <Col xs={6}>{issue?.data?.status}</Col>
          <Col xs={6}>{t('Video link')}</Col>
          <Col xs={6}>{issue?.data?.video_link}</Col>
          <Col xs={6}>
            {t('Images')}
            <div
              className="flex items-center mt-2 px-2 py-1 text-[#6c757d] border-1 border-[#6c757d] w-fit rounded-lg cursor-pointer hover:text-white hover:bg-[#6c757d] active:scale-95"
              onClick={() => setShowUpload(true)}
            >
              <FiPaperclip className="inline-block me-1" />
              {t('Add')}
            </div>
          </Col>
          <Col xs={6}>
            <Row>
              {Array.isArray(issue?.data?.images) ? (
                issue?.data?.images.map((image, i) => (
                  <Col xs={12} key={i}>
                    <a
                      className="flex flex-nowrap items-center cursor-pointer"
                      href={`${config.DATASLAYER_URL}/img/issues/${image.path}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaImage className="me-2" />
                      {i + 1}.{getFileExtension(image.path)}
                    </a>
                  </Col>
                ))
              ) : (
                <></>
              )}
            </Row>
          </Col>
          <Col className="border-b" xs={6}>
            {t('Created')}
          </Col>
          <Col className="border-b" xs={6}>
            {formatDateIndex(issue?.data?.created)}
          </Col>
        </Row>
      </Col>
      <Col xl={9} sm={12}>
        <Row className="mx-2">
          <Col className="border-b border-white ps-0 text-sm" xs={12}>
            {t('ISSUE NAME')}
          </Col>
          <Col className=" ps-0" xs={12}>
            {issue?.data?.name}
          </Col>
          <Col className="border-b border-white ps-0 mt-4 text-sm" xs={12}>
            {t('ISSUE DESCRIPTION')}
          </Col>
          <Col className=" ps-0" xs={12}>
            {issue?.data?.comment}
          </Col>
          <Col className="border-b border-white ps-0 mt-4 text-sm" xs={12}>
            {t('CONVERSATION')}
          </Col>
          <Col className="ps-0" xs={12}>
            {issue?.data?.issue_comments?.length > 0 && (
              <div
                id="comment_history"
                className="my-3 pt-3 h-[350px] overflow-auto border border-[#3c4754] rounded-[13px]"
              ></div>
            )}
            <div id="new_summernote_entry"></div>
          </Col>
          <Col xs={12}>
            <Row className="justify-between mt-3">
              <Col xs="8">
                {commentFiles.map((f, i) => (
                  <span key={i} className="me-2">
                    {f.name}
                  </span>
                ))}
              </Col>
              <Col xs="auto">
                <Row>
                  <input
                    type="file"
                    multiple
                    className="hidden"
                    accept="image/*"
                    ref={commentFilesRef}
                    onChange={(e) => {
                      setCommentFiles([...e.target.files].slice(0, 10))
                    }}
                  />
                  <Col xs="auto">
                    <ButtonMMM onClick={() => commentFilesRef.current.click()}>
                      <FiPaperclip className="inline-block me-1" />
                      {t('Attach files')}
                    </ButtonMMM>
                  </Col>
                  <Col xs="auto">
                    <ButtonMMM
                      onClick={async (e) => {
                        const target = e.currentTarget
                        target.disabled = true
                        const code = window
                          .$('#new_summernote_entry')
                          .summernote('code')
                        const response = await addUserIssueComment({
                          data: { comment: code, issue_id: issueId },
                          files: commentFiles,
                          token,
                        })
                        if (response.ok) {
                          toast.success('Comment added')
                          setCommentFiles([])
                          queryClient.invalidateQueries(['issue', issueId])
                          window
                            .$('#new_summernote_entry')
                            .summernote('code', '')
                        } else {
                          toast.error('Failed to add comment')
                        }
                        target.disabled = false
                      }}
                    >
                      {t('Add comment')}
                    </ButtonMMM>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Modal
        show={showUpload}
        onHide={() => setShowUpload(false)}
        onDrop={(e) => {
          e.preventDefault()
          e.stopPropagation()
          if (e?.dataTransfer?.files?.length) {
            setSelectedFiles([...e?.dataTransfer?.files].slice(0, 10))
          }
        }}
        onDragOver={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <Modal.Header closeButton>{t('Upload images')}</Modal.Header>
        <Modal.Body>
          <input
            type="file"
            multiple
            className="hidden"
            accept="image/*"
            ref={filesRef}
            onChange={(e) => {
              setSelectedFiles([...e.target.files].slice(0, 10))
            }}
          />
          <div
            className="w-full py-2 px-4 border-dotted border-4 !border-[#dcd7ca] text-[#dcd7ca] font-bold flex justify-center user-select-none cursor-pointer"
            onClick={() => filesRef.current.click()}
          >
            {selectedFiles.length ? (
              <Row>
                {selectedFiles.map((file, index) => (
                  <Col className="text-center py-2" xs={12}>
                    {file.name}
                  </Col>
                ))}
              </Row>
            ) : (
              t('Drag and drop up to 10 images')
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Col xs="auto">
              <ButtonMMM
                disabled={!selectedFiles.length}
                onClick={async (e) => {
                  const target = e.currentTarget
                  target.disabled = true
                  const response = await addImagesToIssue({
                    issueId,
                    files: selectedFiles,
                    token,
                  })
                  if (response.ok) {
                    toast.success('Images uploaded')
                    setSelectedFiles([])
                    setShowUpload(false)
                    queryClient.invalidateQueries(['issue', issueId])
                  } else {
                    toast.error('Failed to upload images')
                  }
                }}
              >
                {t('Submit')}
              </ButtonMMM>
            </Col>
            <Col xs="auto">
              <ButtonMMM
                onClick={() => {
                  setSelectedFiles([])
                  setShowUpload(false)
                }}
              >
                {t('Cancel')}
              </ButtonMMM>
            </Col>
          </Row>
        </Modal.Footer>
      </Modal>
    </Row>
  )
}
