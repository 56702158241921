import React, { useRef, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { round, readableNumber, readableNumberMMM } from '../../utility/format'
import { GridTable, DownloadPosition } from '../grid-table/GridTable'
import Loader from '../Loader'
import { getMMMSaturationCurves, getModeltable } from '../../services/model'
import { useQuery } from 'react-query'

import './MMMOptionzationTable.css'
import { useAuth } from '../../providers/AuthProvider'
import { placeholderSaturation, placeholderTable } from './placeholders'
import HelpTooltip from '../HelpTooltip'

const _cell_size = [4, 3, 2, 3, 2, 2]
const _total = _cell_size.reduce((a, b) => a + b, 0)

export default function MMMOptionzationTable({ model, maxHeight = 300 }) {
  const { token, isEssential } = useAuth()
  const requirePro = isEssential
  const { t } = useTranslation()
  const [width, setWidth] = React.useState(0)
  const rowRef = useRef()

  const { data: _table } = useQuery(
    ['model-optimization-table', model.id],
    async () => {
      const res = await getModeltable({
        modelId: model.id,
        token,
      })
      if (res.ok) return await res.json()
    },
    { staleTime: Infinity },
  )
  const table = requirePro ? placeholderTable : _table
  const header = table?.columns?.map((c) => (c === 'CPA' ? 'ROI' : c))
  if (Array.isArray(header)) header[0] = 'Media'

  useEffect(() => {
    if (rowRef.current) {
      setWidth(rowRef.current.offsetWidth - 40)
    }
  }, [rowRef, table])

  const { data, isSuccess, isLoading } = useQuery(
    ['saturation-curves', model.id],
    async () => {
      if (requirePro) return placeholderSaturation
      const res = await getMMMSaturationCurves({
        modelId: model.id,
        token,
      })
      if (res.ok) return await res.json()
    },
    { staleTime: Infinity },
  )
  if (!table) return <Loader />

  // Get index of Share
  const mediaSpendIndex = header.indexOf('Media spend')
  const mediaOutcomeIndex = header.indexOf('Media outcome')
  const mediaContributionIndex = header.indexOf('Media contribution')
  const recommendedSpendIndex = header.indexOf('Recommended spend')
  const ROIIndex = header.indexOf('ROI')
  const maximizedOutcomeIndex = header.indexOf('Maximized outcome')
  const channelMaxSpendIndex = header.indexOf('Channel Max spend')
  const indexOfMediaContributions = header.indexOf('Contribution %')
  let totalMediaSpend = 0
  let totalMediaOutcome = 0

  let smallROI = false

  const rows = table.data.map((row) =>
    row
      .map((cell, index) => {
        const value = isNaN(cell) ? cell : cell < 0.01 ? cell : round(cell, 2)
        switch (index) {
          case mediaContributionIndex:
            return `${value}%`
          case recommendedSpendIndex:
          case ROIIndex:
            return (
              <strong className="text-success">
                {readableNumberMMM(
                  (100 * (row[mediaOutcomeIndex] - row[mediaSpendIndex])) /
                    row[mediaSpendIndex],
                )}
              </strong>
            )
          case maximizedOutcomeIndex:
          case channelMaxSpendIndex:
            return null
          case mediaSpendIndex:
            totalMediaSpend += value
            return readableNumber(value)
          case mediaOutcomeIndex:
            totalMediaOutcome += value
            return readableNumber(value)
          case indexOfMediaContributions:
            return value
          default:
            return readableNumber(value)
        }
      })
      .filter((v) => v !== null),
  )

  const mediaSpendContributionTotal = rows
    .map((r) => r[2])
    .reduce((partial, a) => partial + parseFloat(a.replaceAll('%', '')), 0)

  rows.forEach(
    (r) =>
      (r[indexOfMediaContributions] =
        readableNumber(
          round(
            (r[indexOfMediaContributions] * 100) / mediaSpendContributionTotal,
          ),
          2,
        ) + '%'),
  )

  const dataHeader = header
    .map((c, i) =>
      i === ROIIndex ? (smallROI ? 'Media ROI x1000' : 'Media ROI') : c,
    )
    .filter((c) => c !== 'Channel Max spend')

  let convertibility = false
  let cell_size = _cell_size
  let total = _total
  let otherFooter = []

  if (data && isSuccess) {
    let sumConvertibility = 0
    cell_size = [..._cell_size, 2]
    total += 2
    convertibility = true
    dataHeader.push(t('Convertibility'))
    rows.forEach((r, i) => {
      if (!data?.[r[0]]) return
      try {
        const c =
          Number.parseFloat(r?.[1]?.replaceAll(',', '')) / data?.[r[0]]?.km

        if (isNaN(c)) r.push('')
        else {
          sumConvertibility += c
          r.push(round(c, 2))
        }
      } catch (e) {
        return
      }
    })
    otherFooter = [<strong>{round(sumConvertibility, 2)}</strong>]
  }
  return !width || isLoading ? (
    <Row ref={rowRef} className="w-100">
      <Col xs={12}>
        <Loader />
      </Col>
    </Row>
  ) : (
    <Row>
      <Col xs={12}>
        <GridTable
          header={[dataHeader]}
          headerElement={(cell, i) => (
            <span className="display-blur inline-flex items-center">
              {cell}
              {cell === t('Convertibility') && (
                <HelpTooltip
                  iconProps={{ size: 18, color: 'white' }}
                  className="ms-1"
                  message={t(
                    'How effectively spend marketing inputs convert into desired business outcomes, such as sales, leads, or brand awareness.',
                  )}
                />
              )}
            </span>
          )}
          rows={rows}
          download={DownloadPosition.INDEX}
          downloadFilename={`Dataset_${model?.name}`}
          downloadProcessCells={(cell) => {
            const data = String(
              typeof cell === 'object' ? cell?.props?.children ?? cell : cell,
            ).replaceAll(',', '')
            return data
          }}
          rowsPerPage={rows.length}
          index={(i) => (
            <div className={`grid-table-cell index-cell ${i ? '' : 'first'}`}>
              {i ? i : ''}
            </div>
          )}
          className={`w-100 table-view-data mmm-table relative ${requirePro ? 'blur-under' : ''}`}
          style={{ maxHeight: maxHeight }}
          defaultColumnWidth={(str, i) =>
            Math.floor((cell_size[i] * width) / total)
          }
          groupings={{
            [t('Input')]: 2,
            [t('Output')]: header.length - 3 + (convertibility ? 1 : 0),
          }}
          groupingElement={({ name }) => (
            <Row className="media-marketing-grouping-element px-0 gx-0 py-3">
              <Col xs={12}>{name}</Col>
            </Row>
          )}
          stylesByColumn={[
            undefined,
            { textAlign: 'right' },
            { textAlign: 'right' },
            { textAlign: 'right' },
            { textAlign: 'right' },
            { textAlign: 'right' },
            { textAlign: 'right' },
          ]}
          footer={[
            <strong>Total</strong>,
            <strong>{readableNumber(round(totalMediaSpend, 2))}</strong>,
            <strong>{round(mediaSpendContributionTotal, 2)}%</strong>,
            <strong>{readableNumber(round(totalMediaOutcome, 2))}</strong>,
            <strong>{readableNumber(round(100, 2))}%</strong>,
            <strong className="text-success">
              {readableNumberMMM(
                (100 * (totalMediaOutcome - totalMediaSpend)) / totalMediaSpend,
              )}
            </strong>,
            ...otherFooter,
          ]}
          pagerLast={true}
        />
      </Col>
    </Row>
  )
}
