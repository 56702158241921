import { fetchAPI } from './base'

export function getConnectors({ token }) {
  return fetchAPI({
    endpoint: `dataslayer/connectors`,
    token,
  })
}

export function getAccounts({ datasource, connection, token }) {
  return fetchAPI({
    endpoint: `dataslayer/${datasource}/accounts`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ connections: [connection] }),
    token,
  })
}

export function getMetrics({ datasource, account, callType, token }) {
  let search = ''
  if (account) search += `account=${account}`
  if (callType) {
    if (search) search += '&'
    search += `call_type=${callType}`
  }
  return fetchAPI({
    endpoint: `dataslayer/${datasource}/metrics${search ? `?${search}` : ''}`,
    method: 'GET',
    token,
  })
}

export function checkTask({ task_id, token }) {
  return fetchAPI({
    endpoint: `dataslayer/check_task/${task_id}`,
    token,
  })
}

export function getCustomMetricsFacebook({ data, token }) {
  return fetchAPI({
    endpoint: `dataslayer/facebook/custom_metrics`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    token,
  })
}

export function getCustomMetricsAnalytics_v4({ data, token }) {
  return fetchAPI({
    endpoint: `dataslayer/analytics_v4/custom_metrics`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    token,
  })
}

export function getPlanPriceDifference({ currency, type, token }) {
  return fetchAPI({
    endpoint: `dataslayer/plan-price-difference?currency=${currency}&plan_type=${type}`,
    method: 'GET',
    token,
  })
}

export function changeMorpheusPlan({ currency, type, token }) {
  return fetchAPI({
    endpoint: `dataslayer/update-plan?currency=${currency}&plan_type=${type}`,
    method: 'POST',
    token,
  })
}

export function cancelRenewal({ reason, comment, token }) {
  return fetchAPI({
    endpoint: `dataslayer/cancel-renewal`,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ reason, comment }),
    method: 'POST',
    token,
  })
}

export function continueRenewal({ token }) {
  return fetchAPI({
    endpoint: `dataslayer/continue-renewal`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    token,
  })
}

export function getUserIssues({ datasourceId, teamIssues, token }) {
  return fetchAPI({
    endpoint: `dataslayer/user-issues?datasource_id=${datasourceId || 0}&team_issues=${!!teamIssues}`,
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'GET',
    token,
  })
}

export function getUserIssue({ issueId, token }) {
  return fetchAPI({
    endpoint: `dataslayer/user-issue?issue_id=${issueId}`,
    method: 'GET',
    token,
  })
}

export function addUserIssue({ data, files, token }) {
  const formData = new FormData()
  formData.append('data', JSON.stringify(data))
  files.forEach((file) => {
    formData.append('files', file)
  })

  return fetchAPI({
    endpoint: `dataslayer/add-issue`,
    body: formData,
    method: 'POST',
    token,
  })
}

export function addUserIssueComment({ data, files, token }) {
  const formData = new FormData()
  formData.append('data', JSON.stringify(data))
  files.forEach((file) => {
    formData.append('files', file)
  })

  return fetchAPI({
    endpoint: `dataslayer/add-issue-comment`,
    body: formData,
    method: 'POST',
    token,
  })
}

export function addImagesToIssue({ issueId, files, token }) {
  const formData = new FormData()
  files.forEach((file) => {
    formData.append('files', file)
  })

  return fetchAPI({
    endpoint: `dataslayer/add-images-to-issue/${issueId}`,
    body: formData,
    method: 'POST',
    token,
  })
}
