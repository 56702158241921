import React, { useEffect, useRef, useState } from 'react'
import { Form, Row, Col, Button, Modal, Offcanvas } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { FaEye, FaFilter, FaPlus } from 'react-icons/fa'
import { formatDateIndex } from '../utility/format'
import { useAuth } from '../providers/AuthProvider'
import { useQuery, useQueryClient } from 'react-query'
import DataTable from 'react-data-table-component'
import { Link } from 'react-router-dom'
import Loader from './Loader'
import { config } from '../Constants'
import { addUserIssue, getUserIssues } from '../services/dataslayer'
import CustomSelect from './CustomSelect'
import ButtonMMM from './ButtonMMM'
import { toast } from 'react-toastify'

function StepBall({ index, noLine = false, active, children }) {
  return (
    <Row className="text-sm">
      <Col className="flex items-center whitespace-nowrap" xs={'auto'}>
        <span
          className={`inline-flex items-center place-content-center  rounded-full min-w-[30px] max-w-[30px] min-h-[30px] max-h-[30px] me-2 ${active ? 'bg-[#007bff]' : 'bg-[#505961]'}`}
        >
          {index}
        </span>
        <span>{children}</span>
      </Col>
      {!noLine && (
        <Col className="flex-grow flex items-center">
          <div className="min-w-full border-1 border-[#505961]"></div>
        </Col>
      )}
    </Row>
  )
}

function NewIssue({
  supporters,
  datasources,
  topics,
  selectedFilters,
  onFinish,
}) {
  const { t } = useTranslation()
  const { token } = useAuth()

  const [step, setStep] = useState(0)
  const [selectedFiles, setSelectedFiles] = useState([])

  const filesRef = useRef()
  const config = useRef({
    name: '',
    comment: '',
    assigned_user_id: null,
    topic_id: Object.keys(topics || {})[0],
    datasource_id: null,
    video_link: '',
  })
  const queryClient = useQueryClient()

  let supporter = Object.entries(supporters || {}).find(
    (s) => s[0] === config.current.assigned_user_id,
  )
  supporter = supporter
    ? { value: supporter[0], label: supporter[1] }
    : { value: null, label: t('None') }

  let topic = Object.entries(topics || {}).find(
    (s) => s[0] === config.current.topic_id,
  )
  topic = topic
    ? { value: topic[0], label: topic[1] }
    : { value: null, label: t('None') }

  let datasource = Object.entries(datasources || {}).find(
    (s) => s[0] === config.current.datasource_id,
  )
  datasource = datasource
    ? { value: datasource[0], label: datasource[1] }
    : { value: null, label: t('None') }

  const canContinue =
    step !== 0 || (!!config.current.name && !!config.current.comment)

  return (
    <Row className="mx-2 text-sm justify-center">
      <Col xs={12} className="mb-4 font-bold">
        <Row>
          <Col xs={5}>
            <StepBall index={1} active={step === 0}>
              {t('Information')}
            </StepBall>
          </Col>
          <Col xs={5}>
            <StepBall index={2} active={step === 1}>
              {t('Datasource')}
            </StepBall>
          </Col>
          <Col xs={2}>
            <StepBall index={3} noLine active={step === 2}>
              {t('Upload')}
            </StepBall>
          </Col>
        </Row>
      </Col>
      {step === 0 && (
        <Col xs={12}>
          <Row className="justify-center">
            <Col xs={12}>{t('Enter a name')}</Col>
            <Col className="mt-2" xs={12}>
              <Form.Control
                type="text"
                className="text-input-mmm px-2"
                defaultValue={config.current.name}
                onChange={(e) => {
                  config.current.name = e.target.value
                  setSelectedFiles((f) => [...f])
                }}
              />
            </Col>
            <Col className="mt-4" xs={12}>
              {t('What happened?')}
            </Col>
            <Col className="mt-2" xs={12}>
              <Form.Control
                type="text"
                as="textarea"
                rows={5}
                className="text-input-mmm px-2 py-2"
                defaultValue={config.current.comment}
                onChange={(e) => {
                  config.current.comment = e.target.value
                  setSelectedFiles((f) => [...f])
                }}
              />
            </Col>
            <Col className="mt-2" xs={8}>
              {t('Select a suporter')}
            </Col>
            <Col className="mt-2" xs={8}>
              <CustomSelect
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                placeholder={t('Select a supporter')}
                isClearable={true}
                onChange={(e) => {
                  config.current.assigned_user_id = e?.value
                }}
                options={Object.entries(supporters || {}).map((s) => ({
                  value: s[0],
                  label: s[1],
                }))}
                defaultValue={supporter}
              />
            </Col>
          </Row>
        </Col>
      )}
      {step === 1 && (
        <Col xs={6}>
          <Row>
            <Col xs={12}>{t('Select a topic')}</Col>
            <Col className="mt-2" xs={12}>
              <CustomSelect
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                placeholder={t('Select a topic')}
                onChange={(e) => (config.current.topic_id = e?.value)}
                options={Object.entries(topics || {}).map((s) => ({
                  value: s[0],
                  label: s[1],
                }))}
                defaultValue={topic}
              />
            </Col>
            <Col className="mt-4" xs={12}>
              {t('Select a datasource')}
            </Col>
            <Col className="mt-2" xs={12}>
              <CustomSelect
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                placeholder={t('Select a datasource')}
                onChange={(e) => {
                  config.current.datasource_id = e?.value
                }}
                options={Object.entries(datasources || {}).map((s) => ({
                  value: s[0],
                  label: s[1],
                }))}
                defaultValue={datasource}
                isClearable={true}
              />
            </Col>
          </Row>
        </Col>
      )}
      {step === 2 && (
        <Col
          xs={12}
          onDrop={(e) => {
            e.preventDefault()
            e.stopPropagation()
            if (e?.dataTransfer?.files?.length) {
              setSelectedFiles([...e?.dataTransfer?.files].slice(0, 10))
            }
          }}
          onDragOver={(e) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <Row>
            <Col xs={12} className="mt-2 mb-4 ">
              <input
                type="file"
                multiple
                className="hidden"
                accept="image/*"
                ref={filesRef}
                onChange={(e) => {
                  setSelectedFiles([...e.target.files].slice(0, 10))
                }}
              />
              <div
                className="w-full py-2 px-4 border-dotted border-4 !border-[#dcd7ca] text-[#dcd7ca] font-bold flex justify-center user-select-none cursor-pointer"
                onClick={() => filesRef.current.click()}
              >
                {selectedFiles.length ? (
                  <Row>
                    {selectedFiles.map((file, index) => (
                      <Col className="text-center py-2" xs={12}>
                        {file.name}
                      </Col>
                    ))}
                  </Row>
                ) : (
                  t('Drag and drop up to 10 images')
                )}
              </div>
            </Col>
            <Col xs={12}>
              {t('Video link')} ({t('Optional')})
            </Col>
            <Col className="mt-2" xs={12}>
              <Form.Control
                type="text"
                className="text-input-mmm px-2"
                defaultValue={config.current.video_link}
                onChange={(e) => (config.current.video_link = e.target.value)}
              />
            </Col>
          </Row>
        </Col>
      )}

      <Col className="mt-3 mb-4 flex justify-center" xs={12}>
        {step !== 0 && (
          <ButtonMMM onClick={() => setStep(step - 1)}>{t('Back')}</ButtonMMM>
        )}
        <ButtonMMM
          className="ms-2"
          onClick={async (e) => {
            if (step === 2) {
              const target = e.currentTarget
              target.disabled = true
              const response = await addUserIssue({
                data: config.current,
                files: selectedFiles,
                token,
              })
              if (response.ok) {
                queryClient.invalidateQueries([
                  'issues-morpheus',
                  token,
                  selectedFilters,
                ])
                onFinish()
              } else {
                toast.error(t('Error submitting issue'))
              }
              target.disabled = false
            } else {
              setStep(step + 1)
            }
          }}
          disabled={!canContinue}
        >
          {t(step === 2 ? 'Submit issue' : 'Next')}
        </ButtonMMM>
      </Col>
    </Row>
  )
}

export default function Issues({ pushHeader, popHeader }) {
  const { t } = useTranslation()
  const { token, user } = useAuth()
  const [newIssue, setNewIssue] = useState(false)

  const [showFilters, setShowFilters] = useState(false)
  const [filters, setFilters] = useState({})
  const [selectedFilters, setSelectedFilters] = useState({})

  useEffect(() => {
    pushHeader(t('Issues'))
    return () => popHeader()
  }, [])

  const { data: issues, isLoading } = useQuery(
    ['issues-morpheus', token, selectedFilters],
    async () => {
      const response = await getUserIssues({
        token,
        datasourceId: filters?.datasource?.value,
        teamIssues: filters?.user_company?.value,
      })
      if (response.ok) {
        const res = await response.json()
        res.issues.sort((a, b) => new Date(b.created) - new Date(a.created))
        return res
      }
      throw new Error('Error fetching issues')
    },
    { staleTime: Infinity },
  )

  const columns = [
    {
      name: t('Id'),
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: t('Datasource'),
      selector: (row) => row.datasource?.name ?? '-',
      sortable: true,
    },
    {
      name: t('Status'),
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: t('Created'),
      selector: (row) => new Date(row.created).getTime(),
      cell: (row) => formatDateIndex(row.created),
      sortable: true,
      conditionalCellStyles: [
        {
          when: (row) => true,
          style: {
            textAlign: 'center',
          },
        },
      ],
    },
    {
      name: t('Actions'),
      cell: (row) => (
        <div className="flex justify-start w-full">
          <Button
            as={Link}
            className="button-mmm-actions bg-transparent"
            size="sm"
            to={
              user?.admin
                ? `${config.DATASLAYER_URL}/issues/view/${row.id}`
                : `/issue/${row.id}`
            }
          >
            <FaEye />
          </Button>
        </div>
      ),
      width: '130px',
    },
  ]

  return (
    <Row>
      <Col className="flex justify-center " xs={12}>
        <Row className="[&>*]:min-w-[250px] [&>*]:max-w-[250px] [&>*]:mx-2">
          <Col>
            <Row className="flex-col text-center justify-between min-h-full border-guidePurple border-2 px-4 py-3 rounded-[10px]">
              <Col className="text-3xl" xs={12}>
                {issues?.issues?.length ?? 0}
              </Col>
              <Col className="mt-3" xs={12}>
                {t('Reported issues')}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="flex-col text-center justify-between min-h-full border-guidePurple border-2 px-4 py-3 rounded-[10px]">
              <Col className="text-3xl" xs={12}>
                {issues?.issues?.filter(
                  (i) => i.status === 'OPEN' || i.status === 'UNDER_REVIEW',
                )?.length ?? 0}
              </Col>
              <Col className="mt-3" xs={12}>
                {t('Open issues')}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row className="flex-col text-center justify-between min-h-full border-guidePurple border-2 px-4 py-3 rounded-[10px]">
              <Col className="text-3xl" xs={12}>
                {issues?.issues?.filter(
                  (i) => i.status === 'CLOSED' || i.status === 'FIXED',
                )?.length ?? 0}
              </Col>
              <Col className="mt-3" xs={12}>
                {t('Solved issues')}
              </Col>
            </Row>
          </Col>
          <Col>
            <Row
              className={`*:flex-col text-center justify-between min-h-full border-guidePurple border-2 px-4 py-3 rounded-[10px] border-dotted cursor-pointer hover:scale-105 duration-150 active:scale-100 hover:bg-guidePurple hover:text-white hover:border-white ${Array.isArray(issues?.issues) ? '' : 'pointer-events-none opacity-45'}`}
              onClick={() => setNewIssue(true)}
            >
              <Col
                className="text-3xl flex justify-center items-center"
                xs={12}
              >
                <FaPlus />
              </Col>
              <Col className="mt-3" xs={12}>
                {t('Report issue')}
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className="!my-[20px]  flex justify-end" xs={12}>
        <span
          className="border-white border-1 p-2 rounded-s-full rounded-e-full min-w-[55px] flex justify-center me-4 cursor-pointer active:scale-95"
          onClick={() => setShowFilters(true)}
        >
          <FaFilter size={16} />
        </span>
      </Col>
      <Col xs={12}>
        {Array.isArray(issues?.issues) ? (
          <div className="data-table rounded-t-xl mx-4">
            <DataTable
              theme="mmm-table-theme"
              columns={columns}
              data={issues.issues}
              pagination
              paginationComponentOptions={{
                rowsPerPageText: `${t('Rows per page')}`,
                rangeSeparatorText: `${t('Of')}`,
                selectAllRowsItem: true,
                selectAllRowsItemText: `${t('All')}`,
              }}
              persistTableHead={true}
            />
          </div>
        ) : (
          <Loader />
        )}
      </Col>
      <Modal
        show={newIssue}
        onHide={() => setNewIssue(false)}
        centered
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton>
          <div className="ms-auto font-bold">{t('Submit issue')}</div>
        </Modal.Header>
        <Modal.Body>
          {newIssue && (
            <NewIssue
              supporters={issues.support_admins}
              datasources={issues.datasources}
              topics={issues.topics}
              onFinish={() => setNewIssue(false)}
              selectedFilters={selectedFilters}
            />
          )}
        </Modal.Body>
      </Modal>
      <Offcanvas
        show={showFilters}
        onHide={() => setShowFilters(false)}
        placement="end"
      >
        <Offcanvas.Header closeButton>
          <strong>{t('Filters')}</strong>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {showFilters && (
            <Row className="mx-2">
              <Col className="text-sm" xs={12}>
                {t('Datasource')}
              </Col>
              <Col className="mt-1" xs={12}>
                <CustomSelect
                  options={Object.entries(issues?.datasources || {}).map(
                    (s) => ({ value: s[0], label: s[1] }),
                  )}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  placeholder={t('Select a datasource')}
                  onChange={(e) => {
                    setFilters((f) => ({ ...f, datasource: e }))
                  }}
                  value={filters?.datasource}
                  isClearable={true}
                />
              </Col>
              {user?.company &&
                (user?.company_admin || user?.company_owner) && (
                  <>
                    <Col className="mt-3 text-sm" xs={12}>
                      {t('Company user')}
                    </Col>
                    <Col className="mt-1" xs={12}>
                      <CustomSelect
                        options={[
                          { value: false, label: t('Own issues') },
                          { value: true, label: t('All team issues') },
                        ]}
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={true}
                        placeholder={t('Own issues')}
                        onChange={(e) => {
                          setFilters((f) => ({ ...f, user_company: e }))
                        }}
                        value={filters?.user_company}
                        isClearable={true}
                      />
                    </Col>
                  </>
                )}
              <Col xs={12} className="mt-2 flex justify-center">
                <Row className="min-w-full mt-4">
                  <Col xs={4} className="ps-0">
                    <ButtonMMM
                      className="w-full"
                      onClick={() => {
                        setFilters({})
                        setSelectedFilters({})
                      }}
                    >
                      {t('Clear')}
                    </ButtonMMM>
                  </Col>
                  <Col xs={8} className="pe-0">
                    <ButtonMMM
                      className="w-full"
                      onClick={() => {
                        setSelectedFilters(filters)
                        setShowFilters(false)
                      }}
                    >
                      {t('Apply')}
                    </ButtonMMM>
                  </Col>
                </Row>
              </Col>
            </Row>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </Row>
  )
}
