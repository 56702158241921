import { useState, useEffect, useRef, useMemo } from 'react'
import {
  Row,
  Col,
  Dropdown,
  OverlayTrigger,
  Popover,
  Modal,
  Tooltip,
} from 'react-bootstrap'
import { useQuery, useQueryClient, useInfiniteQuery } from 'react-query'
import {
  FaMagnifyingGlass,
  FaEllipsisVertical,
  FaDownload,
} from 'react-icons/fa6'
import {
  FaChevronLeft,
  FaChevronRight,
  FaQuestionCircle,
  FaSpinner,
} from 'react-icons/fa'
import { GrEdit } from 'react-icons/gr'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import { IoSearchSharp } from 'react-icons/io5'
import { useSearchParams } from 'react-router-dom'

import Loader from './Loader'
import {
  downloadModelFile,
  getColumnSummary,
  getHead,
  getModelImportConfig,
} from '../services/model'
import { formatDateddmmyy } from '../utility/format'
import ButtonMMM from './ButtonMMM'
import { GridTable, DownloadPosition } from './grid-table/GridTable'
import ColumnAndType from './ColumnAndType'
import { useAuth } from '../providers/AuthProvider'
import CustomSelect from './CustomSelect'
import '../styles/viewData.css'
import { HELP_URL } from '../Constants'
import ImportModel from './import-model/ImportModel'
import {
  special_connection_account_datasources,
  special_connection_account_subaccount_datasources,
  special_connection_datasources,
} from './import-model/config'
import HelpTooltip from './HelpTooltip'
import proCrown from '../assets/images/pro_crown.svg'
import { updateModelStatus } from '../services/model'

function NavigatePage({
  page,
  pageOptions,
  columnOptions,
  onChangePage,
  columnsPerPage,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <Row {...props}>
      <Col className="mb-1" xs={12}>
        <Row>
          <Col xs={6} className="mt-2">
            {t('Select page')}:
          </Col>
          <Col xs={6}>
            <CustomSelect
              value={{ label: page + 1, value: page }}
              onChange={(value) => onChangePage(value?.value)}
              options={pageOptions}
              hideSelectedOptions={false}
              isClearable={false}
              placeholder={'Select a page'}
              type={'dark'}
            />
          </Col>
        </Row>
      </Col>
      <Col className="mt-3 mb-1" xs={12}>
        {t('Go to page containing column')}:
      </Col>
      <Col xs={12}>
        <CustomSelect
          value={null}
          onChange={(value) => {
            onChangePage(value?.index)
          }}
          options={columnOptions}
          hideSelectedOptions={false}
          isClearable={false}
          placeholder={'Select a column'}
          type={'dark'}
        />
      </Col>
    </Row>
  )
}

function removeTagsFromCustomConfigurations(customConfigurations) {
  const newCustomConfigurations = JSON.parse(
    JSON.stringify(customConfigurations),
  )
  Object.keys(newCustomConfigurations).forEach((datasource) => {
    if (special_connection_datasources[datasource]) {
      if (newCustomConfigurations?.[datasource]?.associatedTag) {
        newCustomConfigurations[datasource][account].previousAssociatedTag =
          newCustomConfigurations[datasource][account].associatedTag
        delete newCustomConfigurations[datasource].associatedTag
      }
    } else if (special_connection_account_datasources[datasource]) {
      Object.keys(newCustomConfigurations[datasource]).forEach((account) => {
        if (newCustomConfigurations?.[datasource]?.[account]?.associatedTag) {
          newCustomConfigurations[datasource][account].previousAssociatedTag =
            newCustomConfigurations[datasource][account].associatedTag
          delete newCustomConfigurations[datasource][account].associatedTag
        }
      })
    } else if (special_connection_account_subaccount_datasources[datasource]) {
      Object.keys(newCustomConfigurations[datasource]).forEach((account) => {
        Object.values(newCustomConfigurations[datasource][account]).forEach(
          (subaccount) => {
            if (subaccount?.associatedTag) {
              subaccount.previousAssociatedTag = subaccount.associatedTag
              delete subaccount.associatedTag
            }
          },
        )
      })
    }
  })
  return newCustomConfigurations
}

export default function ViewData({
  model,
  maxHeight = '500px',
  rowsPerPage = Infinity,
  pushTitle,
  popTitle,
  ...props
}) {
  const { token, user, refreshUsage } = useAuth()
  const queryClient = useQueryClient()
  const [rows, setRows] = useState([])
  const [searchParams, setSearchParams] = useSearchParams()
  const [formatedRows, setFormatedRows] = useState([])
  const [columnPage, setColumnPage] = useState(0)
  const { t } = useTranslation()
  useEffect(() => {
    pushTitle(`Dataslayer MMM | ${model?.name}`)
    return () => popTitle()
  }, [])

  const [editConnections, setEditConnections] = useState(false)
  const status_edit_import = 'edit_import'
  const modelId = model?.id
  const { data: dataslayerConfig } = useQuery(
    ['dataslayer-config', model?.id],
    async () => {
      const response = await getModelImportConfig({ token, modelId: model?.id })
      if (response.ok) return await response.json()
      throw new Error('Error retrieving dataslayer config')
    },
    { staleTime: Infinity },
  )
  const [metadata, setMetadata] = useState(null)
  useEffect(() => {
    if (dataslayerConfig?.metadata) {
      const newMetadata = JSON.parse(JSON.stringify(dataslayerConfig.metadata))
      newMetadata.customConfigurations = removeTagsFromCustomConfigurations(
        newMetadata.customConfigurations,
      )
      setMetadata(newMetadata)
    }
  }, [dataslayerConfig, editConnections])

  const { data: summary } = useQuery(
    ['column-summary', model?.id, model?.status],
    async () => {
      if (model?.id && model?.status !== 'importing') {
        const response = await getColumnSummary({
          modelId: model?.id,
          token,
        })
        if (response.ok) {
          const summary = await response.json()
          if (!summary)
            setTimeout(
              () =>
                queryClient.invalidateQueries([
                  'model-summary',
                  model?.id,
                  model?.status,
                ]),
              2000,
            )
          else return summary
        }
      }
      return null
    },
    { staleTime: Infinity, retryDelay: 3000 },
  )

  const { data: baseSample, fetchNextPage } = useInfiniteQuery(
    ['viewData-infinite', model?.id, model?.status],
    async ({ pageParam = 0 }) => {
      const error = {
        status: 'error',
        message: 'Error retrieving data',
      }

      if (!model || model?.status === 'importing') return null

      let response = await getHead({
        modelId: model?.id,
        pageSize: 100,
        pageNumber: pageParam + 1,
        token,
      })

      if (!response.ok) return error

      const sample = await response.json()
      if (!sample?.head) return error
      return sample
    },
    {
      getNextPageParam: (_, items) => {
        return items.length
      },
      staleTime: Infinity,
    },
  )
  const sampleData = useMemo(() => {
    if (baseSample?.pages?.filter((v) => v)?.length) {
      const adjustedSample = {
        columns_order:
          baseSample?.pages?.filter((v) => v)?.find((p) => p.columns_order)
            ?.columns_order ?? [],
        head:
          baseSample?.pages
            ?.filter((v) => v)
            ?.reduce((acc, p) => {
              if (p?.head) {
                Object.keys(p.head).forEach((k) => {
                  acc[k] = acc[k] ?? []
                  acc[k].push(...p.head[k])
                })
              }
              return acc
            }, {}) ?? {},
      }
      try {
        const keymap = Object.entries(deletedColumns).reduce((acc, [k, v]) => {
          if (Array.isArray(v)) v?.forEach((c) => (acc[c] = k))
          return acc
        }, {})
        const len = Object.values(adjustedSample?.head ?? {})?.[0]?.length ?? 0
        return {
          ...adjustedSample,
          columns_order: [
            ...adjustedSample.columns_order,
            ...Object.keys(keymap),
          ],
          head: {
            ...adjustedSample.head,
            ...Object.keys(keymap).reduce((acc, k) => {
              acc[k] = Array(len).fill(null)
              return acc
            }, {}),
          },
          deletedColumns: keymap,
        }
      } catch (e) {
        return adjustedSample
      }
    }
    return null
    // eslint-disable-next-line
  }, [baseSample])

  const columnSegmentation = useMemo(() => {
    if (sampleData?.columns_order) {
      const columnsPerPage = 20
      const columns = sampleData.columns_order
      const pages = Math.ceil(columns.length / columnsPerPage)
      const pageOptions = new Array(pages).fill(0).map((_, i) => ({
        label: i + 1,
        value: i,
      }))
      const columnOptions = columns.map((c, i) => ({
        label: c,
        value: c,
        index: Math.floor(i / columnsPerPage),
      }))
      return { columns, pages, columnsPerPage, pageOptions, columnOptions }
    }
  }, [sampleData])

  const { header } = useMemo(() => {
    if (!sampleData || !('columns_order' in sampleData)) return {}

    const begin = columnPage * columnSegmentation.columnsPerPage
    const end = (columnPage + 1) * columnSegmentation.columnsPerPage

    const segmentedSampleData = {
      columns_order: sampleData.columns_order.slice(begin, end),
      head: sampleData.head,
    }
    const currentColumnOrder = segmentedSampleData?.columns_order ?? []
    const customFormat = {}
    let numRows =
      segmentedSampleData?.head[currentColumnOrder?.[0]]?.length ?? 0

    let rows = []
    let formatedRows = []
    for (let i = 0; i < numRows; i++) {
      let row = {}
      let formatedRow = {}
      for (let col of currentColumnOrder) {
        row[col] = segmentedSampleData.head?.[col]?.[i]
        formatedRow[col] =
          customFormat[col]?.[i] ?? segmentedSampleData.head?.[col]?.[i]
      }
      rows.push(row)
      formatedRows.push(formatedRow)
    }
    if (segmentedSampleData.target) {
      currentColumnOrder.push(
        currentColumnOrder.splice(
          currentColumnOrder.indexOf(segmentedSampleData.target),
          1,
        )[0],
      )
    }
    setRows(rows.map((r) => currentColumnOrder.map((k) => r[k])))
    setFormatedRows(
      formatedRows.map((r) => currentColumnOrder.map((k) => r[k])),
    )
    return { header: [currentColumnOrder] }
    // eslint-disable-next-line
  }, [sampleData, columnPage])

  const headerRef = useRef()
  const footerRef = useRef()
  const [calculatedMaxHeight, setCalculatedMaxHeight] = useState(maxHeight)
  const tableReady = Array.isArray(rows) && Array.isArray(header)

  const modelEdits = model?.model_edits ?? Infinity
  const maxEdits = user?.plan_data?.morpheus_limits?.max_updates ?? Infinity
  const canEdit = modelEdits < maxEdits

  const ColNavigation = ({ ...props }) => (
    <Row
      className="justify-content-end flex-nowrap min-w-full ms-3"
      style={{ maxWidth: '150px' }}
    >
      <Col
        xs={'auto'}
        className="flex-row flex smallp items-center justify-center px-0"
        style={{ fontSize: '0.8rem' }}
      >
        <div className="flex-nowrap flex flex-row whitespace-nowrap">
          {t('Showing columns')}
          <strong className="mx-1">
            {1 + columnPage * columnSegmentation.columnsPerPage}
          </strong>
          —
          <strong className="mx-1">
            {(header[0]?.length ?? 0) +
              columnPage * columnSegmentation.columnsPerPage}
          </strong>
        </div>
      </Col>
      <Col
        xs={'auto'}
        className="justify-center flex flex-nowrap px-0 items-center"
      >
        <span
          className="cursor-pointer hover-white p-1 px-2 me-1 text-nowrap"
          disabled={columnPage === 0}
          onClick={() => setColumnPage(Math.max(columnPage - 1, 0))}
        >
          <FaChevronLeft size={24} />
        </span>
        <OverlayTrigger
          rootClose={true}
          trigger={'click'}
          placement="auto"
          delay={{ show: 200, hide: 0 }}
          overlay={(props) => (
            <Popover {...props} style={{ ...props.style, minWidth: '300px' }}>
              <div
                style={{
                  width: '300px',
                  backgroundColor: 'var(--mmm-background)',
                  border: '1px solid var(--mmm-background2)',
                  borderRadius: 5,
                }}
              >
                <NavigatePage
                  page={columnPage}
                  pageOptions={columnSegmentation.pageOptions}
                  columnOptions={columnSegmentation.columnOptions}
                  onChangePage={(p) => setColumnPage(p)}
                  columnsPerPage={columnSegmentation.columnsPerPage}
                  className="p-3"
                />
              </div>
            </Popover>
          )}
        >
          <span className="cursor-pointer hover-white p-1 px-2 me-1 text-nowrap">
            <IoSearchSharp size={18} />
          </span>
        </OverlayTrigger>
        <span
          className="cursor-pointer hover-white p-1 px-2 me-1 text-nowrap"
          disabled={columnPage === columnSegmentation.pages - 1}
          onClick={() =>
            setColumnPage(
              Math.min(columnPage + 1, columnSegmentation.pages - 1),
            )
          }
        >
          <FaChevronRight size={24} />
        </span>
      </Col>
    </Row>
  )

  useEffect(() => {
    const widthCallback = () => {
      if (headerRef.current) {
        const position = $(headerRef.current)?.offset()?.top
        const height = $(headerRef.current)?.outerHeight(true)
        if (Number.isNaN(position) || Number.isNaN(height)) return
        setCalculatedMaxHeight(`calc(100vh - ${position + height + 80}px)`)
      }
    }
    widthCallback()
    window.addEventListener('resize', widthCallback)
    return () => window.removeEventListener('resize', widthCallback)
    // eslint-disable-next-line
  }, [sampleData])

  const [refresh, setRefresh] = useState(0)
  useEffect(() => {
    //Needs a little delay before the table is rendered to start observing on column page change
    setTimeout(() => setRefresh((r) => r + 1, 1000))
  }, [columnPage])
  const viewItem = footerRef.current
  useEffect(() => {
    if (viewItem) {
      let options = {
        root: document.querySelector('.grid-table'),
        rootMargin: '500px',
        threshold: 1,
      }
      const observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if (isIntersecting) fetchNextPage()
      }, options)
      observer.observe(viewItem)
      return () => observer.disconnect()
    }
    // eslint-disable-next-line
  }, [viewItem, refresh])

  return (
    <Row className="max-w-full">
      {model ? (
        <Col xs={12}>
          <Row>
            <Col className="pe-0" xxl={1} lg={2} xs={2}>
              <Row>
                <Col xs={12}>
                  <strong>{t('Updated')}</strong>
                </Col>
                <Col className="font-thin" xs={12}>
                  {formatDateddmmyy(model?.updated_at, ' ')}
                </Col>
              </Row>
            </Col>
            <Col className="pe-0" xxl={1} lg={2} xs={2}>
              <Row>
                <Col xs={12}>
                  <strong>{t('Cols')}</strong>
                </Col>
                <Col className="font-thin" xs={12}>
                  {model.cols}
                </Col>
              </Row>
            </Col>
            <Col className="pe-0" xxl={1} lg={2} xs={2}>
              <Row>
                <Col xs={12}>
                  <strong>{t('Rows')}</strong>
                </Col>
                <Col className="font-thin inline-flex flex-nowrap" xs={12}>
                  {model.rows}
                  <HelpTooltip
                    message={t('Row processing tooltip')}
                    className="ms-2 mt-1"
                  />
                </Col>
              </Row>
            </Col>
            <Col xxl={9} lg={6} xs={6}>
              <Row className="justify-end">
                {metadata && (
                  <OverlayTrigger
                    rootClose={true}
                    trigger={['hover', 'focus']}
                    placement="auto"
                    delay={{ show: 200, hide: 0 }}
                    overlay={(props) =>
                      model.status === 'training' ||
                      model?.status === 'importing' ? (
                        <Tooltip {...props}>
                          <div>
                            {t('The model is currently') + ' ' + model?.status}
                          </div>
                        </Tooltip>
                      ) : (
                        <div></div>
                      )
                    }
                  >
                    <Col xs="auto">
                      <ButtonMMM
                        className="text-[#96CDFF]"
                        disabled={
                          model?.status === 'training' ||
                          model?.status === 'importing' ||
                          !canEdit
                        }
                        onClick={() => {
                          setEditConnections(true)
                          updateModelStatus({
                            modelId,
                            status: status_edit_import,
                            token,
                          })
                        }}
                      >
                        <span className="inline-flex items-center align-middle">
                          {canEdit ? (
                            <>
                              <GrEdit className="me-2" />{' '}
                              {t('Edit connections')}
                            </>
                          ) : (
                            <>
                              <img className="me-2" src={proCrown} />
                              <span>{t('Edit limit reached')}</span>
                            </>
                          )}
                        </span>
                      </ButtonMMM>
                    </Col>
                  </OverlayTrigger>
                )}
                <Col xs="auto">
                  <ButtonMMM
                    className="button-mmm-info"
                    onClick={() => window.open(`${HELP_URL}`, '_blank')}
                  >
                    <span className="inline-flex items-center align-middle">
                      <FaQuestionCircle className="me-2" /> {t('Help')}
                    </span>
                  </ButtonMMM>
                </Col>
                <Col xs="auto">
                  <Dropdown>
                    <Dropdown.Toggle
                      as={ButtonMMM}
                      className="py-1 no-drop-aid button-mmm-info"
                    >
                      <span className="inline-flex items-center mt-1">
                        <FaEllipsisVertical size={14} />
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu
                      variant="dark"
                      style={{ transform: 'translateX(-120px)' }}
                    >
                      <Dropdown.Item
                        onClick={() => {
                          downloadModelFile({ token, modelId: model.id }).then(
                            async (r) => {
                              if (r.ok) {
                                const blob = await r.blob()
                                const url = window.URL.createObjectURL(
                                  new Blob([blob]),
                                )
                                const link = document.createElement('a')
                                link.href = url
                                link.setAttribute('download', `${model.id}.csv`)
                                document.body.appendChild(link)
                                link.click()
                                link.parentNode.removeChild(link)
                              }
                            },
                          )
                        }}
                        className="model-dropdown"
                      >
                        <span className="inline-flex items-center">
                          <FaDownload className="me-2" />{' '}
                          {t('Download dataset')}
                        </span>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Col>
                <Col xs="auto" className="hidden">
                  <ButtonMMM className="py-1 hidden">
                    <span className="inline-flex items-center">
                      <FaMagnifyingGlass className="me-2" /> {t('Explore')}
                    </span>
                  </ButtonMMM>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <div className="view-data-table-container w-100">
              {tableReady && (
                <GridTable
                  key={columnPage}
                  rows={formatedRows}
                  rowsPerPage={rowsPerPage}
                  header={header}
                  datasetLength={model?.rows}
                  download={DownloadPosition.INDEX}
                  downloadFilename={`Dataset_${model?.name}`}
                  downloadProcessCells={(cell) =>
                    String(cell).replaceAll(',', '.') ?? cell
                  }
                  cellElement={(str) => (
                    <span className="single-line">{str}</span>
                  )}
                  headerElement={(column, width) => (
                    <ColumnAndType
                      column={column}
                      summary={summary?.[column]}
                      width={width}
                      columnToType={{}}
                      columnDescription={
                        sampleData.columns_description?.[column]
                      }
                      onFill={() => setColumnToFill(column)}
                      header={header}
                      setRows={setFormatedRows}
                      sampleData={sampleData}
                      model={model}
                    />
                  )}
                  index={(i, v) => (
                    <div
                      className={`grid-table-cell index-cell ${
                        i ? '' : 'first'
                      }`}
                    >
                      <div className="flex flex-col justify-center items-center ">
                        {i ? i : ''}
                      </div>
                    </div>
                  )}
                  className={`w-100 table-view-data`}
                  defaultColumnWidth={(str) =>
                    Math.min(Math.max(200, str.length * 11), 400)
                  }
                  style={{ maxHeight: calculatedMaxHeight }}
                  pagerLast={true}
                  scroll={({ scrollElement }) => (
                    <div
                      className="w-full"
                      ref={headerRef}
                      style={{
                        marginBottom: '3px',
                      }}
                    >
                      {(columnSegmentation?.pages ?? 0) > 1 && (
                        <ColNavigation className="d-lg-inline-block d-none" />
                      )}
                    </div>
                  )}
                  modelId={model?.id}
                >
                  <div
                    className="w-100 flex justify-center items-center px-2 py-4 bold sticky more-data-message-view-data"
                    style={{ left: '0%' }}
                    ref={footerRef}
                  >
                    {'Loading more rows'}{' '}
                    <FaSpinner className="ms-2 animate-spin" size={30} />
                  </div>
                </GridTable>
              )}
            </div>
          </Row>
          {editConnections && (
            <>
              <Modal
                show={editConnections}
                onHide={() => setEditConnections(false)}
                className="model-import-dataslayer"
                backdrop="static"
              >
                <Modal.Header closeButton className="pb-0"></Modal.Header>
                <Modal.Body className="mt-1">
                  {editConnections && (
                    <ImportModel
                      baseModel={model}
                      metadata={metadata}
                      onFinish={(modelId, plan) => {
                        setEditConnections(false)
                        queryClient.invalidateQueries(['model', modelId])
                        queryClient.invalidateQueries([
                          'dataslayer-config',
                          modelId,
                        ])
                        queryClient.invalidateQueries([
                          'column-summary',
                          model?.id,
                          model?.status,
                        ])
                        queryClient.invalidateQueries([
                          'viewData-infinite',
                          model?.id,
                          model?.status,
                        ])
                        queryClient.invalidateQueries([
                          'viewData-infinite',
                          model?.id,
                          'training',
                        ])
                        queryClient.invalidateQueries([
                          'viewData-infinite',
                          model?.id,
                          'created',
                        ])
                        queryClient.invalidateQueries([
                          'viewData-infinite',
                          model?.id,
                          'error',
                        ])
                        if (plan === 'Essential') {
                          searchParams.set('step', 1)
                          setSearchParams(searchParams)
                        }
                        refreshUsage()
                      }}
                    />
                  )}
                </Modal.Body>
              </Modal>
            </>
          )}
        </Col>
      ) : (
        <Col xs={12}>
          <Loader className="mt-5" />
        </Col>
      )}
    </Row>
  )
}
