import { useState, useMemo, useRef, useEffect } from 'react'
import { Container, Row, Col, Modal, Form } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { useDebouncedCallback } from 'use-debounce'
import { toast } from 'react-toastify'

import Splash from './Splash'
import Connections from './Connections'
import { useAuth } from '../../providers/AuthProvider'
import { getConnectors } from '../../services/dataslayer'
import Loader from '../Loader'
import ButtonMMM from '../ButtonMMM'
import ConfigureInputs from './ConfigureInputs'
import ProcessImport from './ProcessImport'
import {
  createEmptyModel,
  getModelLocalFiles,
  tagDataslayerImportToLocalFile,
  uploadModelLocalFiles,
} from '../../services/model'
import { baseFormat } from '../../utility/model'
import {
  custom_static_config_datasource,
  disabled_connectors,
  special_connection_account_datasources,
  special_connection_account_subaccount_datasources,
  special_connection_datasources,
  special_connection_datasources_without_local,
} from './config'
import CustomSelect from '../CustomSelect'
import proCrown from '../../assets/images/pro_crown.svg'
import proPlan from '../../assets/images/pro_plan.svg'
import essentialsPlan from '../../assets/images/essentials.svg'
import UpgradeToPro from '../mmm-graphs/UpgradeToPro'
import ConfirmImport from './ConfirmImport'
import { FaMagnifyingGlass } from 'react-icons/fa6'
import PlanData from './PlanData'
import useStoreState from '../../hooks/UseStoreState'

const STEPS = [Splash, Connections, ConfigureInputs]

const TIME_OPTIONS = [
  {
    value: 1000 * 60 * 60 * 24 * 365 * 2,
    label: 'Last 2 years',
  },
  { value: 1000 * 60 * 60 * 24 * 365, label: 'Last year' },
  { value: 1, label: 'Autoselect from files' },
]

export default function ImportModel({
  onFinish,
  baseModel,
  metadata,
  showSplash,
}) {
  const { t } = useTranslation()
  const [configureStep, setConfigureStep] = useState(null)
  const queryClient = useQueryClient()

  const { token, user, isEssential } = useAuth()
  const [importId] = useState(() => Math.random().toString(36).substring(7))

  const { data: model } = useQuery(
    ['empty-model-dataslayer-import', importId],
    async () => {
      if (baseModel) return baseModel
      const response = await createEmptyModel({
        token,
        name: `new dataslayer model - ${baseFormat(new Date())}`,
      })
      if (response.ok) return await response.json()
      throw new Error('Error creating empty model')
    },
    { staleTime: Infinity },
  )
  const [file, setFile] = useState([])

  const { data: localFiles, isLoadingLocalFiles } = useQuery(
    ['model-local-files', model?.id],
    async () => {
      if (!model?.id) return []

      const response = await getModelLocalFiles({ modelId: model?.id, token })
      if (response.ok) return await response.json()
      throw new Error('Error getting local files')
    },
    { staleTime: Infinity },
  )

  useEffect(() => {
    if (
      (localFiles?.length &&
        localFiles.some((f) => f.status === 'not_processed')) ||
      isLoadingLocalFiles
    ) {
      const iv = setInterval(
        () => queryClient.invalidateQueries(['model-local-files', model?.id]),
        5000,
      )
      return () => clearInterval(iv)
    }
  }, [localFiles])

  const { isLoading: isUploadingFiles } = useQuery(
    ['update-local-files', file, model?.id],
    async () => {
      if (file?.length && model?.id) {
        const response = await uploadModelLocalFiles({
          modelId: model?.id,
          files: file,
          token,
        })
        if (response.ok) {
          queryClient.invalidateQueries(['model-local-files', model?.id])
          const results = await response.json()
          file.forEach((f, i) => {
            if (!results?.find((i) => i.name === f.name)) {
              toast.error(
                t('The file does not meet the expected format.') + ' ' + f.name,
              )
            }
          })
          setFile([])
        } else {
          setFile([])
          toast.error(t('Error uploading files'))
          throw new Error('Error uploading files')
        }
      }
    },
  )

  const [fileStatus, setFileStatus] = useState(
    metadata?.fileStatus ?? { active: false },
  )

  useEffect(() => {
    if (!localFiles?.length && timeFrame === 1) {
      setTimeFrame(TIME_OPTIONS[0].value)
    }
  }, [localFiles?.length])

  useEffect(() => {
    if (Array.isArray(localFiles)) {
      try {
        localFiles
          .filter((f) => f.tag_type === 'dataslayer')
          .forEach((localFile) => {
            if (Array.isArray(localFile?.columns)) {
              const columns = new Set(localFile.columns.map((c) => c.name))
              const tag = localFile.filename
              Object.entries(fileStatus?.selected ?? {}).forEach(
                ([index, selected]) => {
                  if (selected?.[tag]) {
                    Object.keys(selected[tag]).forEach((column) => {
                      if (!columns.has(column)) {
                        delete selected[tag][column]
                      }
                    })
                    selected[tag] = { ...selected[tag] }
                  }
                },
              )
              if (
                fileStatus?.selectedDataCol?.[tag]?.value &&
                !columns.has(fileStatus.selectedDataCol[tag].value)
              )
                delete fileStatus.selectedDataCol[tag]
            }
          })
        setFileStatus({ ...fileStatus })
      } catch (e) {
        console.error(
          `Error filtering after local files were updated`,
          e,
          localFiles,
          fileStatus,
        )
      }
    }
  }, [localFiles])

  const [selectedAccounts, setSelectedAccounts] = useState(
    metadata?.selectedAccounts ?? {},
  )

  const countAccounts = useRef(metadata?.countAccounts ?? 0)
  const [timeFrame, setTimeFrame] = useState(() => {
    return (
      TIME_OPTIONS.find((o) => o.label === metadata?.timeLabel)?.value ??
      metadata?.timeFrame ??
      TIME_OPTIONS[0].value
    )
  })

  useEffect(() => {
    if (file?.length) {
      setTimeFrame(TIME_OPTIONS[2]?.value)
    }
  }, [file])

  const timeLabel = useMemo(
    () => TIME_OPTIONS.find((option) => option.value === timeFrame)?.label,
    [timeFrame],
  )
  const [timeDimension, setTimeDimension] = useState(
    metadata?.timeDimension ?? 'YearWeek',
  )
  const [customConfigurations, setCustomConfigurations] = useState(
    metadata?.customConfigurations ?? {},
  )

  const [mediaChannelDatasources, setMediaChannelDatasources] = useState(
    metadata?.mediaChannelDatasources ?? [],
  )
  const [contextVariablesDatasources, setContextVariablesDatasources] =
    useState(metadata?.contextVariablesDatasources ?? [])
  const [kpiDatasources, setKpiDatasources] = useState(
    metadata?.kpiDatasources ?? [],
  )

  const [mediaUserAccount, setMediaUserAccount] = useState(
    metadata?.mediaUserAccount ?? {},
  )
  const [campaignAdAggregation, setCampaignAdAggregation] = useState(
    metadata?.campaignAdAggregation ?? {
      campaign: {},
      adgroup: {},
      ad: {},
    },
  )
  const [mediaChannelMetrics, setMediaChannelmetrics] = useState(
    metadata?.mediaChannelMetrics ?? ['Cost'],
  )

  const [contextVariables, setContextVariables] = useState(
    metadata?.contextVariables ?? {},
  )
  const [disabledContextVariables, setDisabledContextVariables] = useState(
    metadata?.disabledContextVariables ?? {},
  )
  const [kpis, setKpis] = useState(metadata?.kpis ?? {})

  const essentialsLocation = useRef(metadata?.essentialsLocation)

  const nameRegister = useRef(metadata?.nameRegister ?? {})
  const metricsNameRegister = useRef(metadata?.metricsNameRegister ?? {})

  const { data: connectors, isLoading: isLoadingConnectors } = useQuery(
    ['connectors', token],
    async () => {
      if (token) {
        const res = await getConnectors({ token })
        if (res.ok) {
          const connectors = await res.json()
          if (!connectors.find((c) => c.api_entry === 'json_csv_xml'))
            connectors.push({
              api_entry: 'json_csv_xml',
              name: 'JSON/CSV/XML',
              accounts: [{ id: 'json_csv_xml', name: 'JSON/CSV/XML' }],
            })
          connectors.sort((a, b) => a.name.localeCompare(b.name))
          return connectors.filter((c) => !disabled_connectors.includes(c.name))
        }
      }
    },
    { staleTime: Infinity },
  )

  const [enrichmentDatasources, setEnrichmentDatasources] = useState(
    metadata?.enrichmentDatasources ?? {},
  )

  useEffect(() => {
    if (!model?.id) return
    const promises = []
    const tagCustomConfig = (datasource, account, subaccount, config) => {
      if (!config || config?.associatedTag || config.static) return
      config.associatedTag = 'loading_tag'
      const fixedConfig = { ...config, datasource }
      if (account) fixedConfig.connections = [{ id: account }]
      if (!fixedConfig.initial_date)
        fixedConfig.initial_date = new Date(
          Date.now() - 1000 * 60 * 60 * 24 * 365,
        )

      promises.push(
        tagDataslayerImportToLocalFile({
          modelId: model?.id,
          replaceTag: config.previousAssociatedTag,
          config: {
            name: `${datasource}-${Date.now()}.csv`,
            table_config: fixedConfig,
          },
          token,
        })
          .then(async (response) => {
            if (response.ok) {
              const localModelFile = await response.json()
              if (localModelFile?.filename) {
                config.associatedTag = localModelFile.filename
                return true
              }
            }
            config.associatedTag = 'error_tag'
            return false
          })
          .catch((r) => false),
      )
    }

    Object.keys(customConfigurations).forEach((datasource) => {
      if (special_connection_datasources_without_local.includes(datasource))
        return
      if (special_connection_datasources[datasource])
        tagCustomConfig(
          datasource,
          null,
          null,
          customConfigurations[datasource],
        )
      else if (special_connection_account_datasources[datasource])
        Object.entries(customConfigurations[datasource] ?? {}).forEach(
          ([account, config]) =>
            tagCustomConfig(datasource, account, null, config),
        )
      else if (special_connection_account_subaccount_datasources[datasource])
        Object.entries(customConfigurations[datasource] ?? {}).forEach(
          ([account, subaccounts]) =>
            Object.entries(subaccounts).forEach(([subaccount, config]) =>
              tagCustomConfig(datasource, account, subaccount, config),
            ),
        )
    })
    if (promises.length)
      Promise.all(promises).then((result) =>
        queryClient.invalidateQueries(['model-local-files', model?.id]),
      )
  }, [customConfigurations, model?.id])

  const windowCount = useRef(0)
  useEffect(() => {
    const onFocus = () => {
      if (windowCount.current > 0) {
        windowCount.current--
        queryClient.invalidateQueries(['connectors', token])
      }
    }
    const onBlur = () => windowCount.current++
    window.addEventListener('focus', onFocus)
    window.addEventListener('blur', onBlur)
    return () => {
      window.removeEventListener('focus', onFocus)
      window.removeEventListener('blur', onBlur)
    }
  }, [])

  const [planMode, setPlanMode] = useState(() => {
    if (metadata?.plan) {
      if (metadata.plan !== 'Essential' && isEssential) return 'Essential'
      return metadata.plan
    }
    return isEssential ? 'Essential' : 'Pro'
  })

  const [showUpgrade, setShowUpgrade] = useState(false)

  const importSteps = useMemo(() => {
    const result = showSplash ? [...STEPS] : [...STEPS.slice(1)]
    if (planMode === 'Essential')
      return result.filter((step) => step !== ConfigureInputs)
    return result
  }, [planMode])

  const [showFinishWarning, setShowFinishWarning] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const CurrentStep =
    currentStep === importSteps.length
      ? ProcessImport
      : importSteps?.[currentStep] ?? importSteps[importSteps.length - 1]
  const hideButtonNext =
    (currentStep === 0 && importSteps.length === 3) ||
    currentStep === importSteps.length

  const hideButtonBack = currentStep === 0 || currentStep === importSteps.length
  const hideButtonBreadcrumbs =
    planMode === 'Essential' ||
    currentStep === importSteps.length ||
    (currentStep === 0 && showSplash)

  const _currentStep = currentStep - (importSteps.length === 3 ? 1 : 0)
  const isAccountsStep = _currentStep === 0
  const isConfigStep = _currentStep === 1

  const validConfig = () => {
    const fileMediaChannels = fileStatus?.['selected']?.[0] ?? {}
    const filekpi = fileStatus?.['selected']?.[2] ?? {}
    const activeMediaChannelFile = Object.values(fileMediaChannels).some((v) =>
      Object.values(v).some((v) => v),
    )
    const activeKPIFile = Object.values(filekpi).some((v) =>
      Object.values(v).some((v) => v),
    )
    const activeKPICustom = Object.keys(customConfigurations)
      .filter((k) => custom_static_config_datasource.includes(k))
      .some((datasource) =>
        Object.values(customConfigurations[datasource]).some(
          (v) => v.kpis && Object.values(v.kpis).some((v) => v),
        ),
      )
    return (
      (mediaChannelDatasources.length > 0 || activeMediaChannelFile) &&
      (Object.values(kpis)
        .map((v) => Object.values(v))
        .flat()
        .some((v) => v) ||
        activeKPIFile ||
        activeKPICustom)
    )
  }
  const validLocalFiles = localFiles?.some(
    (localFile) => localFile.status === 'processed',
  )

  let disableNext = true
  let showNext = true

  if (planMode === 'Essential') {
    showNext = !(showSplash && currentStep === 0)
    disableNext =
      countAccounts.current !== 1 ||
      !Object.values(kpis?.analytics_v4 ?? {}).some((v) => v)
  } else
    disableNext =
      (isAccountsStep &&
        countAccounts.current < 1 &&
        !validLocalFiles &&
        !Object.keys(customConfigurations).length) ||
      (isConfigStep && !validConfig()) ||
      isUploadingFiles

  const plans = useMemo(() => {
    const plans = [
      {
        label: (
          <Row className="max-w-max">
            <Col className="flex items-center pe-0" xs={'auto'}>
              <img
                src={essentialsPlan}
                alt="Pro"
                className="min-w-[20px] max-w-[20px] ms-1"
              />
            </Col>
            <Col className="text-lg" xs={6}>
              {t('Essential')}
            </Col>
          </Row>
        ),
        value: 'Essential',
      },
    ]

    if (!isEssential)
      plans.push({
        label: (
          <Row className="max-w-max">
            <Col className="flex items-center pe-0" xs={'auto'}>
              <img
                src={proPlan}
                alt="Pro"
                className="min-w-[20px] max-w-[20px] ms-1"
              />
            </Col>
            <Col className="text-lg" xs={6}>
              {t('Pro')}
            </Col>
          </Row>
        ),
        value: 'Pro',
      })
    else
      plans.push({
        label: (
          <Row className="max-w-max opacity-80">
            <Col className="flex items-center pe-0" xs={'auto'}>
              <img
                src={proCrown}
                alt="Pro"
                className="min-w-[20px] max-w-[20px] ms-1"
              />
            </Col>
            <Col className="text-lg" xs={6}>
              {t('Pro')}
            </Col>
          </Row>
        ),
        value: 'Pro',
        disabled: true,
      })

    return plans
  }, [user])

  const [filterPlaceholder, setFilterPlaceholder] = useState('')
  const [filterValue, setFilterValue] = useState('')
  const updateFilter = useDebouncedCallback((e) => setFilterValue(e), 600)

  const [showPlanData, setShowPlanData] = useState(false)

  const [storedData, setStoredData, forceRewrite] = useStoreState({
    key: 'hiddenImportPlanPopup',
  })

  const shouldTweakFileParams = useMemo(() => {
    if (planMode === 'Essential' && currentStep === importSteps?.length - 1) {
      try {
        if (localFiles?.length === 1) {
          const dateColumns = localFiles[0].columns.filter(
            (c) => c.type === 'datetime',
          )
          if (dateColumns.length > 1) return true
          else if (
            dateColumns?.some((c) => c.options?.datetime_options?.length > 1)
          )
            return true
        }
      } catch (e) {}
    }
    return false
  }, [planMode, currentStep, localFiles])

  const [classifiedDatasources, setClassifiedDatasources] = useState(false)

  if (isLoadingConnectors || !model?.id)
    return (
      <Row className="min-h-full">
        <Col className="flex items-center justify-center" xs={12}>
          <Loader />
        </Col>
      </Row>
    )

  if (showPlanData) {
    return (
      <PlanData
        onNext={() => setShowPlanData(false)}
        storedData={storedData}
        setStoredData={setStoredData}
      />
    )
  }

  return (
    <>
      <Container
        className={`import-dataslayer-container flex flex-col flex-nowrap justify-between ${showFinishWarning ? '!hidden' : ''}`}
      >
        <Row className="justify-between">
          <Col className="flex items-center" xs={'auto'}>
            {filterPlaceholder && (
              <div className="relative">
                <FaMagnifyingGlass
                  size={18}
                  style={{ top: 'calc(50% - 9px)' }}
                  className="absolute left-[10px]"
                />
                <Form.Control
                  className="text-input-mmm ps-[20px] max-w-[300px] min-h-full"
                  defaultValue={filterValue}
                  onChange={(e) => updateFilter(e.target.value)}
                  placeholder={t(filterPlaceholder)}
                />
              </div>
            )}
          </Col>
          <Col className="flex justify-end" xs={'auto'}>
            {currentStep !== importSteps?.length && (
              <div className="max-w-[300px] min-w-[300px] py-1">
                <CustomSelect
                  value={plans.find((p) => p.value === planMode)}
                  options={plans}
                  type={'highImpact'}
                  className="basic-single w-full"
                  onChange={(e) => {
                    if (e.value === 'Pro' && isEssential) {
                      setShowUpgrade(true)
                      return
                    }
                    if (e.value === 'Essential') {
                      setKpis({})
                      setContextVariables({})
                      setMediaChannelDatasources([])
                      setSelectedAccounts(metadata?.selectedAccounts ?? {})
                    }
                    setCurrentStep(0)
                    setPlanMode(e.value)
                  }}
                />
              </div>
            )}
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <CurrentStep
              currentStep={currentStep}
              setCurrentStep={setCurrentStep}
              connectors={connectors}
              file={file}
              setFile={setFile}
              selectedAccounts={selectedAccounts}
              setSelectedAccounts={setSelectedAccounts}
              nameRegister={nameRegister.current}
              metricsNameRegister={metricsNameRegister.current}
              setStep={setConfigureStep}
              step={configureStep}
              timeFrame={timeFrame}
              setTimeFrame={setTimeFrame}
              timeDimension={timeDimension}
              setTimeDimension={setTimeDimension}
              mediaChannelDatasources={mediaChannelDatasources}
              setMediaChannelDatasources={setMediaChannelDatasources}
              contextVariablesDatasources={contextVariablesDatasources}
              setContextVariablesDatasources={setContextVariablesDatasources}
              kpiDatasources={kpiDatasources}
              setKpiDatasources={setKpiDatasources}
              mediaUserAccount={mediaUserAccount}
              setMediaUserAccount={setMediaUserAccount}
              mediaChannelMetrics={mediaChannelMetrics}
              setMediaChannelmetrics={setMediaChannelmetrics}
              contextVariables={contextVariables}
              setContextVariables={setContextVariables}
              kpis={kpis}
              setKpis={setKpis}
              onFinish={onFinish}
              countAccounts={countAccounts}
              disabledContextVariables={disabledContextVariables}
              setDisabledContextVariables={setDisabledContextVariables}
              customConfigurations={customConfigurations}
              setCustomConfigurations={setCustomConfigurations}
              fileStatus={fileStatus}
              setFileStatus={setFileStatus}
              model={model}
              modelId={model?.id}
              localFiles={localFiles}
              isUploadingFiles={isUploadingFiles}
              timeLabel={timeLabel}
              campaignAdAggregation={campaignAdAggregation}
              setCampaignAdAggregation={setCampaignAdAggregation}
              enrichmentDatasources={enrichmentDatasources}
              setEnrichmentDatasources={setEnrichmentDatasources}
              essentialsLocation={essentialsLocation}
              plan={planMode}
              filterPlaceholder={filterPlaceholder}
              setFilterPlaceholder={setFilterPlaceholder}
              filterValue={filterValue}
              setFilterValue={setFilterValue}
              setShowPlanData={
                storedData?.hidePlan ? () => {} : setShowPlanData
              }
              classifiedDatasources={classifiedDatasources}
              setClassifiedDatasources={setClassifiedDatasources}
              token={token}
            />
          </Col>
        </Row>
        {CurrentStep === Connections && planMode === 'Essential' && (
          <Row>
            <Col className="flex flex-nowrap items-end" xs={12}>
              <span className="me-1 text-red-300 !text-2xl max-h-[25px] inline-block">
                *
              </span>
              <span className="text-sm">{t('Fields required')}</span>
            </Col>
          </Row>
        )}
        <Row className="inline-flex flex-row flex-nowrap justify-between mt-2 mb-3">
          <Col className="" xs={'auto'}>
            <ButtonMMM
              className={`${hideButtonBack ? 'opacity-0 pointer-events-none' : ''}`}
              onClick={() => setCurrentStep((s) => s - 1)}
            >
              {t('Back')}
            </ButtonMMM>
          </Col>
          <Col
            xs="auto"
            className={`${hideButtonBreadcrumbs ? 'opacity-0 pointer-events-none' : ''}`}
          >
            <Row className="h-full">
              {importSteps.map((_, index) => (
                <Col
                  xs="auto"
                  className={`${index === currentStep ? '' : 'cursor-pointer'} inline-flex items-center ${index > currentStep && disableNext ? 'opacity-60 pointer-events-none' : ''}`}
                  key={index}
                  onClick={() => setCurrentStep(index)}
                >
                  <span
                    className={`rounded-full min-h-3 min-w-3 max-w-3 max-h-3 block duration-500 ${index === currentStep ? 'bg-white' : 'bg-gray-500'} `}
                  ></span>
                </Col>
              ))}
            </Row>
          </Col>
          <Col className="" xs={'auto'}>
            {showNext && (
              <ButtonMMM
                className={`${hideButtonNext ? 'opacity-0 pointer-events-none' : ''}`}
                onClick={() => {
                  if (
                    planMode === 'Essential' &&
                    currentStep === importSteps.length - 1
                  ) {
                    if (localFiles?.length && localFiles.length > 1) {
                      toast.error(
                        t(
                          'Multiple files selected for an essentials import. Please select only one file.',
                        ),
                      )
                      return
                    } else if (localFiles?.length === 1) {
                      const dateColumns = localFiles[0].columns.filter(
                        (c) => c.type === 'datetime',
                      )
                      if (dateColumns.length === 0) {
                        toast.error(
                          t(
                            'No valid date columns found in the selected file. Please select a file with at least one date column.',
                          ),
                        )
                        return
                      }
                    }
                    setShowFinishWarning(true)
                  } else setCurrentStep((s) => s + 1)
                }}
                disabled={disableNext}
              >
                {t(
                  currentStep === importSteps.length - 1 &&
                    !shouldTweakFileParams
                    ? 'Finish'
                    : 'Next',
                )}
              </ButtonMMM>
            )}
          </Col>
        </Row>
      </Container>
      <Modal
        show={showUpgrade}
        size="xl"
        onHide={() => setShowUpgrade(false)}
        backdrop="static"
        className="model-import-dataslayer"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="min-h-[80vh] relative">
            {showUpgrade && <UpgradeToPro />}
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showFinishWarning}
        size="xl"
        onHide={() => setShowFinishWarning(false)}
        className="model-import-dataslayer"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <ConfirmImport
            onConfirm={() => {
              setShowFinishWarning(false)
              setCurrentStep(currentStep + 1)
            }}
            onCancel={() => setShowFinishWarning(false)}
            fileStatus={fileStatus}
            setFileStatus={setFileStatus}
            file={localFiles?.[0]}
            shouldTweakFileParams={
              shouldTweakFileParams && localFiles?.length > 0
            }
          />
        </Modal.Body>
      </Modal>
    </>
  )
}
