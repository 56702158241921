import React, { useState, useEffect } from 'react'
import DataTable, { createTheme } from 'react-data-table-component'
import {
  Row,
  Col,
  Button,
  Offcanvas,
  Modal,
  OverlayTrigger,
  Popover,
} from 'react-bootstrap'
import { useQuery } from 'react-query'
import { getModelHistory } from '../services/model'
import { useAuth } from '../providers/AuthProvider'
import { useTranslation } from 'react-i18next'
import { formatDateIndex } from '../utility/format'
import Loader from './Loader'

function formatTime(seconds, t) {
  if (seconds < 60) {
    return `${seconds} ${t('seconds')}`
  }
  if (seconds < 3600) {
    return `${Math.floor(seconds / 60)} ${t('minutes')} ${seconds % 60} ${t('seconds')}`
  }
  return `${Math.floor(seconds / 3600)} ${t('hours')} ${Math.floor((seconds % 3600) / 60)}  ${t('minutes')}`
}

export default function ModelHistory({ model }) {
  const { t } = useTranslation()
  const { token } = useAuth()
  const { data: trainingHistory } = useQuery(
    ['modelHistory', model.id],
    async () => {
      const response = await getModelHistory({ modelId: model.id, token })
      return response.json()
    },
    { staleTime: Infinity },
  )

  const columns = [
    {
      name: t('Status'),
      selector: (row) => row.status,
      sortable: true,
      width: '150px',
    },
    {
      id: 'start_time',
      name: t('Training started'),
      selector: (row) => new Date(row.start_time).getTime(),
      cell: (row) => formatDateIndex(new Date(row.start_time)),
      sortable: true,
    },
    {
      name: t('Training finished'),
      selector: (row) => new Date(row.end_time).getTime(),
      cell: (row) =>
        row.end_time ? formatDateIndex(new Date(row.end_time)) : '--',
      sortable: true,
    },
    {
      name: t('Execution time'),
      selector: (row) => row.execution_time,
      cell: (row) => formatTime(row.execution_time, t),
      sortable: true,
    },
    {
      name: t('Media channels'),
      selector: (row) => row.media_channels,
      sortable: true,
    },
    {
      name: t('Context variables'),
      selector: (row) => row.context_variables,
      cell: (row) => row.context_variables,
      sortable: true,
    },
    {
      name: t('Accuracy'),
      selector: (row) => row.accuracy,
      cell: (row) => `${row.accuracy}%`,
      sortable: true,
    },
  ]
  const paginationComponentOptions = {
    rowsPerPageText: `${t('Rows per page')}`,
    rangeSeparatorText: `${t('Of')}`,
    selectAllRowsItem: true,
    selectAllRowsItemText: `${t('All')}`,
  }

  return (
    <Row>
      <Col xs={12}>
        {Array.isArray(trainingHistory) ? (
          <DataTable
            theme="mmm-table-theme"
            columns={columns}
            data={trainingHistory}
            pagination
            paginationComponentOptions={paginationComponentOptions}
            defaultSortAsc={false}
            defaultSortFieldId={'start_time'}
          />
        ) : (
          <Loader />
        )}
      </Col>
    </Row>
  )
}
