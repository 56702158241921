import { Row, Col } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import ModelContentInfobox from './ModelContentInfobox'
import ChannelSpendChange from './ChannelSpendChange'
import MMMOptionzationTable from './mmm-graphs/MMMOptionzationTable'
import MMMPerformance from './mmm-graphs/MMMPerformance'
import ExportReport from './ExportReport'
import HelpTooltip from './HelpTooltip'

export default function Optimization({ model }) {
  const { t } = useTranslation()

  return (
    <Row>
      <Col xs={12}>
        <ModelContentInfobox
          applyInfoboxClass={true}
          collapsable={true}
          name={'optimization_infobox'}
          className="mb-2"
        >
          {t('Optimization infobox')}
        </ModelContentInfobox>
      </Col>
      <Col className="mt-2 background-secondary rounded-xl px-4 py-4" xs={12}>
        <Row className="justify-between">
          <Col
            xs={'auto'}
            className="text-xl font-medium inline-flex items-center"
          >
            {t('Actual scenario optimization')}
            <HelpTooltip
              message={t('Actual scenario optimization help')}
              className="ms-2 mt-1"
            />
          </Col>
          <Col className="hide-essential" xs="auto">
            <ExportReport />
          </Col>
        </Row>
        <Row className="mt-4 mx-3">
          <Col className="text-xl" xs={12}>
            {model?.training_config?.target ?? t('KPI')}
          </Col>
          <Col xs={12}>
            <ChannelSpendChange model={model} />
          </Col>
        </Row>
        <Row className="mt-4 mx-3">
          <Col xs={12}>
            <Row className="outline-main rounded-xl mx-2 py-3">
              <Col
                className="text-xl py-1 mb-3 font-medium inline-flex items-center"
                xs={12}
              >
                {t('Optimization model results')}
                <HelpTooltip
                  message={t('Optimization model results help')}
                  className="ms-2 mt-1"
                />
              </Col>
              <Col className="text-center" xs={5}>
                {t('Current Expenses')}
              </Col>
              <Col className="text-center" xs={7}>
                {t('Model results')}
              </Col>
              <Col className="optimization-table-container mt-2" xs={12}>
                <MMMOptionzationTable model={model} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="mt-4 mx-3">
          <Col xs={12}>
            <Row className="outline-main rounded-xl mx-2 py-3">
              <Col
                className="text-xl py-1 mb-3 font-medium inline-flex items-center"
                xs={12}
              >
                {t('Optimization observations')}
                <HelpTooltip
                  message={t('Optimization observations help')}
                  className="ms-2 mt-1"
                />
              </Col>
              <Col xs={12}>
                <div className="mx-4">
                  <MMMPerformance model={model} />
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
