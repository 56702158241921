import React, { useState, useEffect } from 'react'
import { Row, Col, Image, Button } from 'react-bootstrap'
import ButtonMMM from './ButtonMMM'
import { useTranslation } from 'react-i18next'
import { FaArrowRight } from 'react-icons/fa6'
import { FaExternalLinkAlt } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../providers/AuthProvider'

function GuideCard({ index, children, subChildren }) {
  return (
    <>
      <div className="text-5xl absolute -top-14 font-bold rounded-full bg-guidePurpleEmphasis max-w-20 min-w-20 min-h-20 max-h-20 flex items-center justify-center left-[140px] z-10 shadow-orb-guide">
        {index}
      </div>
      <Row className="w-100 border-guidePurple rounded-2xl border-3 min-h-full rotate-z">
        <Col
          className="text-center text-2xl text-guidePurple py-5 absolute top-0 min-h-full inline-flex items-center justify-center"
          xs={12}
        >
          <div>{children}</div>
        </Col>
        <Col
          className="text-center text-2xl text-guidePurple py-5 min-h-full inline-flex items-center justify-center"
          xs={12}
        >
          {subChildren}
        </Col>
      </Row>
    </>
  )
}

export default function UserGuide() {
  const { user } = useAuth()
  const { t } = useTranslation()
  const hasAccess = user?.plan !== 'Free'
  const navigate = useNavigate()

  return (
    <Row>
      {hasAccess ? (
        <>
          <Col className="text-center" xs={12}>
            {t('To get started')},{' '}
            <span className="font-bold">
              {t(
                "go to the 'Index' section in the menu to start with Marketing Mix Modeling.",
              )}
            </span>
          </Col>
          <Col className="flex justify-center mt-3" xs={12}>
            <ButtonMMM type="white" onClick={() => navigate('/')}>
              <div className="inline-flex flex-nowrap items-center text-xl">
                {t('GO TO INDEX')}
                <FaArrowRight className="ms-2" />
              </div>
            </ButtonMMM>
          </Col>
        </>
      ) : (
        <>
          <Col className="text-center text-pretty" xs={12}>
            {t(
              'Your 7-day Free Trial of the Essential Plan has finished. Click on the button below to talk to an expert on the team and',
            )}{' '}
            <strong>{t('request more trial days for free')}</strong>
          </Col>
          <Col className="flex justify-center mt-3" xs={12}>
            <ButtonMMM
              type="white"
              className="hs-cta-trigger-button hs-cta-trigger-button-112840609740"
            >
              {t('REQUEST MORE TRIAL DAYS')}
            </ButtonMMM>
          </Col>
        </>
      )}
      <Col className="mt-4" xs={12}>
        <div className="flex justify-content-center relative flex-wrap">
          <div className="min-w-96 max-w-96 mx-2 relative rotate-container mt-20">
            <GuideCard
              index={1}
              subChildren={
                <span className="text-base">
                  {t(
                    "Connect to any data source and automatically classify your data to build your dataset. It's quick and easy, so you can get started right away!",
                  )}
                </span>
              }
            >
              {t('Automatically')}{' '}
              <span className="font-semibold">{t('upload your data')}</span>
            </GuideCard>
          </div>
          <div className="min-w-96 max-w-96 mx-2 relative rotate-container mt-20">
            <GuideCard
              index={2}
              subChildren={
                <span className="text-base">
                  {t(
                    'Choose your training speed! Get results in less than 1 minute. Just a few clicks, have your results in seconds!',
                  )}
                </span>
              }
            >
              <span className="font-semibold">{t('Train')}</span>{' '}
              {t('your Marketing Mix Modeling')}
            </GuideCard>
          </div>
          <div className="min-w-96 max-w-96 mx-2 relative rotate-container mt-20">
            <GuideCard
              index={3}
              subChildren={
                <span className="text-sm">
                  {t(
                    'Discover which media channels have impacted your KPIs (like sales) the most. Plan your future budget allocation without needing advanced math or programming skills!',
                  )}
                </span>
              }
            >
              <div className="text-lg">
                {t('Analyze the')}{' '}
                <span className="font-semibold">{t('Insights')}</span>{' '}
                {t('and plan')}{' '}
                <span className="font-semibold">
                  {t('your future budget allocation')}
                </span>
                {t('in minutes!')}
              </div>
            </GuideCard>
          </div>
        </div>
      </Col>
      <Col xs={12}>
        <div className="mt-14"></div>
      </Col>
      <Col
        className="flex items-center justify-center flex-wrap flex-col "
        xs={12}
      >
        <div className="relative">
          <Image
            src="/CTA Section.png"
            alt="Guide 1"
            className="w-100 max-w-[900px]"
          />
          <button
            className="absolute right-10 min-h-12 max-h-12 bg-gradient-to-b from-[#7724FF] to-[#5714CF] rounded-xl active:scale-95 md:block sm:hidden hidden"
            style={{ top: 'calc(50% - 24px)' }}
            onClick={() => {
              window.open(
                'https://contact.dataslayer.ai/meetings/juliamoreno/morpheus-mmm',
                '_blank',
              )
            }}
          >
            <span className="font-bold">{t('BOOK A DEMO')}</span>
          </button>
        </div>
        <button
          className="min-h-12 max-h-12 mt-2 bg-gradient-to-b from-[#7724FF] to-[#5714CF] rounded-xl active:scale-95 md:hidden sm:block"
          onClick={() => {
            window.open(
              'https://contact.dataslayer.ai/meetings/juliamoreno/morpheus-mmm',
              '_blank',
            )
          }}
        >
          <span className="font-bold">{t('BOOK A DEMO')}</span>
        </button>
      </Col>
      <Col className="mt-5" xs={12}>
        <Row className="justify-center mt-5">
          <Col xl={8} md={10} xs={12}>
            <div className="faq-container p-5 rounded-3xl text-[24px] flex flex-col">
              <a
                className="text-[2.5rem] font-bold text-center mb-12 no-underline"
                href="https://support.dataslayer.ai/morpheus"
                target="_blank"
              >
                <span className="font-span-gradient-white">FAQs</span>
              </a>
              <a
                className="mx-2 mb-8 inline-flex items-center text-white no-underline hover:underline"
                href={
                  'https://support.dataslayer.ai/what-is-morpheus-and-its-relation-to-marketing-mix-modeling'
                }
                target="_blank"
              >
                <span className="me-4">01</span>
                <span className="">
                  {t(
                    'What is Morpheus and its relation to Marketing Mix Modeling',
                  )}
                </span>
                <span className="ms-4 cursor-pointer">
                  <FaExternalLinkAlt />
                </span>
              </a>
              <a
                className="mx-2 mb-8 inline-flex items-center text-white no-underline hover:underline"
                href={
                  'https://support.dataslayer.ai/understanding-the-basics-of-morpheus-dataslayers-marketing-mix-modeling-platform'
                }
                target="_blank"
              >
                <span className="me-4">02</span>
                <span className="">
                  {t('Understanding the Basics of Morpheus')}
                </span>
                <span className="ms-4 cursor-pointer">
                  <FaExternalLinkAlt />
                </span>
              </a>
              <a
                className="mx-2 mb-8 inline-flex items-center text-white no-underline hover:underline"
                href={
                  'https://support.dataslayer.ai/step-by-step-guide-to-running-a-marketing-mix-modeling-analysis'
                }
                target="_blank"
              >
                <span className="me-4">03</span>
                <span className="">
                  {t('Step-by-Step Guide to Running a Marketing Mix Modeling')}
                </span>
                <span className="ms-4 cursor-pointer">
                  <FaExternalLinkAlt />
                </span>
              </a>
              <a
                className="mx-2 mb-8 inline-flex items-center text-white no-underline hover:underline"
                href={
                  'https://support.dataslayer.ai/automated-data-upload-section-for-marketing-mix-modeling-in-morpheus'
                }
                target="_blank"
              >
                <span className="me-4">04</span>
                <span className="">
                  {t('Automated Data Upload Section in Morpheus')}
                </span>
                <span className="ms-4 cursor-pointer">
                  <FaExternalLinkAlt />
                </span>
              </a>
              <div className="mx-2 mb-8 inline-flex items-center ">
                <span className="me-4 opacity-0 select-none">05</span>
                <a
                  className="underline text-lg text-white text-[18px] no-underline hover:underline"
                  href="https://support.dataslayer.ai/morpheus"
                >
                  {t('View more related articles')}
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}
