import { useState, useMemo, useEffect } from 'react'
import { Row, Col, Image } from 'react-bootstrap'
import { RxCross2 } from 'react-icons/rx'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { toast } from 'react-toastify'
import 'react-circular-progressbar/dist/styles.css'

import { useAuth } from '../../providers/AuthProvider'
import {
  getModel,
  importDataslayer,
  storeFailedBlend,
} from '../../services/model'
import ModelContentInfobox from '../ModelContentInfobox'
import { baseFormat } from '../../utility/model'
import {
  special_id_accounts,
  special_connection_datasources,
  special_connection_account_datasources,
  AVAILABLE_ENRICHMENTS,
} from './config'

function getImage(datasource) {
  if (datasource === 'Tik Tok') return 'TikTok'
  return datasource
    .replaceAll('/', '_')
    .replaceAll(/\s\(Early Access\)/g, '')
    .replaceAll(/\s*$/g, '')
}

function ErrorItem({ error, setDatasourceError }) {
  const [errorDatatasource, message] = useMemo(() => {
    try {
      let datasource = null
      const regex = /\$\$ERROR_DATASOURCE__([^$]+)\$\$/g
      const modifiedStr = error.replace(regex, (match, ds) => {
        datasource = ds
        return ''
      })
      return [datasource, modifiedStr]
    } catch (e) {
      return [null, error]
    }
  }, [error])

  useEffect(() => {
    if (errorDatatasource) setDatasourceError(true)
  }, [errorDatatasource])

  return (
    <Row className=" flex-nowrap items-center justify-center min-w-full">
      {errorDatatasource ? (
        <>
          <Col
            xs={12}
            style={{ maxWidth: '45px' }}
            className="px-0 inline-flex flex-nowrap items-center justify-end"
          >
            <Image
              src={`/dataslayer_datasources/${getImage(errorDatatasource)}.png`}
              width={45}
              style={{ filter: 'blur(0.5px)' }}
            />
          </Col>
          <Col
            className="flex items-center px-0"
            xs={12}
            style={{ maxWidth: 'calc(100% - 45px)' }}
          >
            <span className="font-bold block min-h-full ms-1 me-3 whitespace-nowrap ">
              {errorDatatasource}:
            </span>
            {message}
          </Col>
        </>
      ) : (
        <>
          <Col
            className="px-0 text-center"
            xs={12}
            style={{ maxWidth: 'calc(100% - 15px)' }}
          >
            {error}
          </Col>
        </>
      )}
    </Row>
  )
}

function ErrorReport({ connectors, errors }) {
  const [datasourceError, setDatasourceError] = useState(false)
  const widthProps = datasourceError
    ? {
        xl: 10,
        md: 12,
        xs: 12,
      }
    : {
        xl: 6,
        md: 8,
        xs: 12,
      }
  return (
    <Row className="justify-center mt-4 ">
      {errors.map((error, index) => {
        return (
          <Col
            className="font-bold  flex items-center text-sm mb-2"
            key={index}
            {...widthProps}
            style={{ maxWidth: 'calc(100% - 60px)' }}
          >
            <ErrorItem error={error} setDatasourceError={setDatasourceError} />
          </Col>
        )
      })}
    </Row>
  )
}

function removeTagsFromCustomConfigurations(customConfigurations) {
  const newCustomConfigurations = JSON.parse(
    JSON.stringify(customConfigurations),
  )
  Object.keys(newCustomConfigurations).forEach((datasource) => {
    if (special_connection_datasources[datasource]) {
      if (newCustomConfigurations?.[datasource]?.associatedTag) {
        newCustomConfigurations[datasource][account].previousAssociatedTag =
          newCustomConfigurations[datasource][account].associatedTag
        delete newCustomConfigurations[datasource].associatedTag
      }
    } else if (special_connection_account_datasources[datasource]) {
      Object.keys(newCustomConfigurations[datasource]).forEach((account) => {
        if (newCustomConfigurations?.[datasource]?.[account]?.associatedTag) {
          newCustomConfigurations[datasource][account].previousAssociatedTag =
            newCustomConfigurations[datasource][account].associatedTag
          delete newCustomConfigurations[datasource][account].associatedTag
        }
      })
    }
  })
  return newCustomConfigurations
}

export default function ProcessImport({
  connectors,
  selectedAccounts,
  countAccounts,
  nameRegister,
  timeFrame,
  timeDimension,
  mediaChannelDatasources,
  contextVariablesDatasources,
  kpiDatasources,
  mediaUserAccount,
  contextVariables,
  kpis,
  onFinish,
  metricsNameRegister,
  customConfigurations,
  currentStep,
  setCurrentStep,
  disabledContextVariables,
  fileStatus,
  modelId,
  step,
  mediaChannelMetrics,
  timeLabel,
  campaignAdAggregation,
  localFiles,
  enrichmentDatasources,
  plan,
  essentialsLocation,
}) {
  const queryClient = useQueryClient()
  const { t } = useTranslation()
  const { token } = useAuth()
  const [localModelId, setLocalModelId] = useState(null)
  const [proceed, setProceed] = useState(true)

  useEffect(() => {
    if (
      Array.isArray(localFiles) &&
      localFiles.every((l) => l?.status !== 'not_processed')
    )
      setProceed(true)
  }, [localFiles])

  const { data: model } = useQuery(
    ['model-progress', localModelId],
    async () => {
      if (!localModelId) return null
      const response = await getModel({ modelId: localModelId, token })
      if (!response?.ok) throw new Error(response?.statusText)
      const model = await response.json()
      if (
        model.status === 'importing' ||
        (plan === 'Essential' && model.status !== 'training')
      ) {
        setTimeout(() => {
          queryClient.invalidateQueries(['model-progress', localModelId])
        }, 3000)
      } else if (plan !== 'Essential' && model.status === 'created') {
        queryClient.invalidateQueries(['models', token])
        setTimeout(() => {
          onFinish(localModelId, plan)
        }, 2000)
      } else if (plan === 'Essential' && model.status === 'training') {
        queryClient.invalidateQueries(['models', token])
        setTimeout(() => {
          onFinish(localModelId, plan)
        }, 2000)
      }
      return model
    },
  )

  useEffect(() => {
    try {
      if (window.createModel || !proceed) return
      window.createModel = true
      setTimeout(() => {
        window.createModel = false
      }, 2000)
      if (plan === 'Essential' && localFiles?.length === 1) {
        const file = localFiles[0]
        const datetimeColumn =
          file?.columns?.find(
            (c) =>
              c.name === fileStatus?.selectedDateCol?.[file.filename]?.label,
          ) || file?.columns?.find((c) => c.type === 'datetime')
        const mediaChannelColumns = file.columns.filter(
          (c) => c.type !== 'datetime',
        )
        if (datetimeColumn && mediaChannelColumns.length) {
          fileStatus = {
            selected: {
              0: {
                [file.filename]: mediaChannelColumns.reduce((acc, c) => {
                  acc[c.name] = true
                  return acc
                }, {}),
              },
            },
            selectedDateCol: {
              [file.filename]: {
                label: datetimeColumn.name,
                value: datetimeColumn.name,
              },
            },
            selectedOption: fileStatus?.selectedOption,
          }
        } else {
          toast.error(
            'The file does not include a valid datetime column or does not have any media channel columns',
          )
          setCurrentStep((s) => s - 1)
          return
        }
      }

      const metadata = {
        configureStep: step,
        fileStatus,
        selectedAccounts,
        countAccounts: countAccounts?.current,
        timeFrame,
        timeDimension,
        customConfigurations:
          removeTagsFromCustomConfigurations(customConfigurations),
        mediaChannelDatasources,
        contextVariablesDatasources,
        kpiDatasources,
        mediaUserAccount,
        mediaChannelMetrics,
        contextVariables,
        disabledContextVariables,
        kpis,
        nameRegister,
        metricsNameRegister,
        timeLabel,
        campaignAdAggregation,
        enrichmentDatasources,
        essentialsLocation: essentialsLocation.current,
        plan,
      }
      if (enrichmentDatasources?.['Weather data']?.contextVariables) {
        if (
          Object.values(
            enrichmentDatasources?.['Weather data']?.contextVariables,
          ).some((v) => v) &&
          !enrichmentDatasources?.['Weather data']?.kwargs?.location
        ) {
          setCurrentStep((s) => s - 1)
          toast.error('Weather data is missing a location')
        }
      }

      const accounts = Object.keys(selectedAccounts).map((datasource) => {
        const special_id_key = special_id_accounts[datasource]
        return {
          datasource: datasource,
          accounts: Object.keys(selectedAccounts[datasource]).map((acc) => ({
            account_id: acc,
            groups: Object.keys(selectedAccounts[datasource][acc]).map(
              (group) => ({
                group,
                subaccounts: Object.entries(
                  selectedAccounts[datasource][acc][group],
                ).reduce((accounts, [subacc, valid]) => {
                  if (valid) {
                    const item = { ...nameRegister[subacc], connection_id: acc }
                    if (special_id_key) {
                      const id = item.id
                      item.id = item[special_id_key]
                      item[special_id_key] = id
                    }
                    accounts.push(item)
                  }
                  return accounts
                }, []),
              }),
            ),
          })),
        }
      })

      const contextVars = Object.keys(contextVariables).reduce((acc, k) => {
        if (disabledContextVariables[k]) return acc
        const item = {
          datasource: k,
          metrics: Object.entries(contextVariables[k]).reduce(
            (acc, [metric, valid]) => {
              if (valid)
                acc.push({
                  id: metric,
                  name: metricsNameRegister[k][metric],
                })
              return acc
            },
            [],
          ),
        }
        if (item.metrics.length > 0) acc.push(item)
        return acc
      }, [])
      const KPIdatasource = Object.keys(kpis)[0]

      let fileConfig = {}
      const keySteps = [
        'media_channel_columns',
        'context_variables_columns',
        'kpi_columns',
      ]
      Object.keys(fileStatus?.selected ?? {}).forEach((key) => {
        const stepKey = keySteps[key]
        const data = fileStatus.selected[key]
        Object.keys(data).forEach((filename) => {
          fileConfig[filename] = fileConfig[filename] ?? {
            filename,
            media_channel_columns: [],
            context_variables_columns: [],
            kpi_columns: [],
            time_dimension_column: null,
            time_dimension_format: null,
          }
          Object.entries(data[filename]).forEach(
            ([column, active]) =>
              active && fileConfig[filename][stepKey].push(column),
          )
        })
      })
      Object.entries(fileStatus?.selectedDateCol ?? {}).forEach(
        ([filename, dateColumn]) => {
          const item = fileConfig[filename]
          if (item) item.time_dimension_column = dateColumn?.value
        },
      )
      Object.entries(fileStatus?.selectedOption ?? {}).forEach(
        ([filename, options]) => {
          const item = fileConfig[filename]
          if (item) {
            const dateCol = item?.time_dimension_column
            item.time_dimension_format = options?.[dateCol]?.value
          }
        },
      )
      fileConfig = Object.values(fileConfig).filter(
        (config) =>
          (config.media_channel_columns.length ||
            config.context_variables_columns.length ||
            config.kpi_columns.length) &&
          config.time_dimension_column,
      )
      const adaptedEnrichents = {}
      if (enrichmentDatasources)
        Object.keys(enrichmentDatasources).forEach((datasource) => {
          const enrichment = enrichmentDatasources[datasource]
          const contextVariables = enrichment?.contextVariables ?? {}
          const metrics = Object.entries(contextVariables).filter(
            ([id, active]) => active,
          )
          const originalMetrics =
            AVAILABLE_ENRICHMENTS.find(
              (v) => v.name === datasource,
            )?.metrics() ?? []
          const items = metrics
            .map(([id, _]) => originalMetrics.find((m) => m.id === id))
            .filter((v) => v)
          if (!items.length) return
          adaptedEnrichents[datasource] = {
            metrics: items,
            kwargs: JSON.parse(JSON.stringify(enrichment?.kwargs)),
          }
          const country_keys = ['country', 'country_source', 'country_target']
          country_keys.forEach((key) => {
            if (adaptedEnrichents[datasource]?.kwargs?.[key]) {
              adaptedEnrichents[datasource].kwargs[key] = {
                name: adaptedEnrichents[datasource].kwargs?.[key].label,
                country_code: adaptedEnrichents[datasource].kwargs?.[key].value,
              }
            }
          })
        })

      const dataslayerConfig = {
        media_channels: mediaChannelDatasources,
        datasource_accounts: accounts,
        context_variables: contextVars,
        kpi: {
          datasource: KPIdatasource,
          kpi_id: Object.keys(kpis?.[KPIdatasource] ?? {})?.[0],
          kpi_name:
            metricsNameRegister?.[KPIdatasource]?.[
              Object.keys(kpis?.[KPIdatasource] ?? {})?.[0]
            ],
        },
        aggregate_datasource: mediaUserAccount,
        aggregate_dimensions: campaignAdAggregation,
        time_dimension: timeDimension,
        initial_date: new Date(Date.now() - (timeFrame?.value ?? timeFrame)),
        file_config: { files: fileConfig },
        custom_configurations: customConfigurations,
        enrichment_datasource: adaptedEnrichents,
        essentials_location: essentialsLocation.current,
        metadata,
      }

      importDataslayer({
        token,
        modelId,
        modelName: `new dataslayer model - ${baseFormat(new Date())}`,
        essential: plan === 'Essential',
        config: dataslayerConfig,
      })
        .then(async (res) => {
          if (res.ok) {
            const model = await res.json()
            model.status = 'importing'
            queryClient.setQueryData(['model-progress', modelId], model)
            setLocalModelId(modelId)
            setTimeout(
              () => queryClient.invalidateQueries(['model-progress', modelId]),
              3000,
            )
          } else {
            storeFailedBlend({
              data: dataslayerConfig,
              token,
            })
            const data = await res.json()
            toast.error(
              data?.detail ?? 'Error creating model, please contact support',
            )
            setCurrentStep((s) => s - 1)
          }
        })
        .catch((e) => {
          console.error(e)
          setCurrentStep((s) => s - 1)
          toast.error('Error creating model, please contact support')
          storeFailedBlend({
            data: dataslayerConfig,
            token,
          })
        })
    } catch (e) {
      console.error(e)
      setCurrentStep((s) => s - 1)
      toast.error('Error creating model, please contact support')
      storeFailedBlend({
        data: {
          js_error: e.toString(),
        },
        token,
      })
    }
  }, [proceed])

  const importProgress = model?.model_progress
  const progress = importProgress?.progress ?? 0
  const importMessage = importProgress?.info ?? 'Preparing your import...'
  const hasErrors =
    model?.status === 'import_error_api' ||
    model?.status === 'import_error_workers'
  const errors = model?.model_messages ?? [
    'Unexpected error during importation',
  ]

  return (
    <Row
      className="min-h-full items-center justify-start flex-col mt-4"
      style={{ minHeight: `${window.innerHeight - 300}px` }}
    >
      <Col className="text-3xl text-center mb-3" xs={12}>
        {hasErrors ? (
          <div className="relative inline-flex w-full justify-center">
            <Col xs={12} className="max-w-12">
              <div className="rounded-full bg-red-600 w-fit me-2 p-1">
                <RxCross2 size={40} />
              </div>
            </Col>
            <Col
              xs={'auto'}
              style={{ maxWidth: 'calc(100% - 48px)' }}
              className="ms-2"
            >
              <div className="text-3xl font-semibold mt-1">
                {t('An error has occurred while extracting your data')}.
              </div>
            </Col>
          </div>
        ) : (
          t('Loading your table...')
        )}
      </Col>
      <>
        {modelId &&
          (hasErrors ? (
            <>
              <Col xs={12} className="max-h-96">
                <ErrorReport errors={errors} connectors={connectors} />
              </Col>
              <Col className="mt-3 flex justify-center" xs={12}>
                <button
                  className="border-white rounded-3xl bg-transparent py-1 text-white text-lg"
                  onClick={() => setCurrentStep((s) => currentStep - 1)}
                >
                  {t('BACK')}
                </button>
              </Col>
            </>
          ) : (
            <Col xs={12}>
              <Row>
                <Col className="flex items-center justify-center" xs={12}>
                  <div className="min-w-64 max-w-64">
                    <CircularProgressbar
                      value={Math.min(progress, 96)}
                      text={`${Math.min(progress, 99)}%`}
                      strokeWidth={10}
                      styles={buildStyles({
                        rotation: 0.25,
                        strokeLinecap: 'rounded',
                        pathTransitionDuration: 0.5,
                        pathColor: `#4240B5`,
                        trailColor: 'var(--mmm-background2)',
                        textColor: 'var(--mmm-white-color)',
                        text: {
                          fontSize: '25px',
                        },
                      })}
                    />
                  </div>
                </Col>
                <Col className="text-center mt-2" xxl={12}>
                  <ModelContentInfobox padding="" className="text-center">
                    {proceed
                      ? importMessage
                      : 'Loading your files and datasources...'}
                  </ModelContentInfobox>
                </Col>
              </Row>
            </Col>
          ))}
      </>
    </Row>
  )
}
